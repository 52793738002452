import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "user-search-wrapper" }
const _hoisted_3 = { class: "input-with-button" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_6 = {
  key: 1,
  class: "user-dropdown"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "plan-selection" }
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  key: 0,
  class: "court-selection"
}
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "date-selection" }
const _hoisted_13 = {
  key: 1,
  class: "time-selection"
}
const _hoisted_14 = ["onUpdate:modelValue"]
const _hoisted_15 = ["value"]
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "select-plan-page",
    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _cache[16] || (_cache[16] = _createElementVNode("h1", null, "Escolha Seu Plano", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { for: "user-search" }, "Usuário:", -1)),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDropdown = true)),
            disabled: _ctx.selectedUser !== null,
            placeholder: "Digite o nome ou email do usuário",
            class: "search-input"
          }, null, 40, _hoisted_4), [
            [_vModelText, _ctx.searchQuery]
          ]),
          (_ctx.selectedUser)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.deselectUser && _ctx.deselectUser(...args))),
                class: "alterar-cliente-button"
              }, " Alterar Cliente "))
            : _createCommentVNode("", true)
        ]),
        (_ctx.loadingUsers)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[10] || (_cache[10] = [
              _createElementVNode("div", { class: "loader" }, null, -1),
              _createElementVNode("p", null, "Carregando usuários...", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.showDropdown && !_ctx.loadingUsers && _ctx.filteredUsers.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredUsers, (user) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: user.id,
                  onClick: _withModifiers(($event: any) => (_ctx.selectUser(user)), ["stop"])
                }, _toDisplayString(user.nome) + " - " + _toDisplayString(user.email), 9, _hoisted_7))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[12] || (_cache[12] = _createElementVNode("label", { for: "plan" }, "Plano:", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedPlan) = $event)),
          id: "plan"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan) => {
            return (_openBlock(), _createElementBlock("option", {
              key: plan.id,
              value: plan.id
            }, _toDisplayString(plan.name), 9, _hoisted_9))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.selectedPlan]
        ])
      ]),
      (_ctx.requiresCourt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _cache[13] || (_cache[13] = _createElementVNode("label", { for: "court" }, "Quadra:", -1)),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedCourt) = $event)),
              id: "court"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courts, (court) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: court.id,
                  value: court.id
                }, _toDisplayString(court.nome), 9, _hoisted_11))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.selectedCourt]
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        _cache[14] || (_cache[14] = _createElementVNode("label", { for: "date" }, "Data de Início:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedDate) = $event)),
          id: "date"
        }, null, 512), [
          [_vModelText, _ctx.selectedDate]
        ])
      ]),
      (_ctx.requiresTime)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _cache[15] || (_cache[15] = _createElementVNode("label", null, "Horários:", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedTimes, (time, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "time-dropdown"
              }, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((_ctx.selectedTimes[index]) = $event),
                  class: "time-select"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimes, (timeOption) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: timeOption,
                      value: timeOption
                    }, _toDisplayString(timeOption), 9, _hoisted_15))
                  }), 128))
                ], 8, _hoisted_14), [
                  [_vModelSelect, _ctx.selectedTimes[index]]
                ]),
                (_ctx.selectedTimes.length > 1)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: ($event: any) => (_ctx.removeTime(index)),
                      class: "remove-time-button"
                    }, " ❌ ", 8, _hoisted_16))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            _createElementVNode("button", {
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.addTime && _ctx.addTime(...args))),
              class: "add-time-button"
            }, "Adicionar Horário")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.submitReservation && _ctx.submitReservation(...args))),
        class: "plan-button"
      }, "Confirmar Reserva")
    ])
  ]))
}