import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "admin-reports-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "options" }
const _hoisted_4 = { class: "generate-section" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_9 = {
  key: 1,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", null, "📊 Gerar Relatórios", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Selecione o tipo de relatório:", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedReport) = $event))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("option", { value: "usuarios" }, "Usuários", -1),
          _createElementVNode("option", { value: "financeiro" }, "Financeiro", -1),
          _createElementVNode("option", { value: "reservas" }, "Reservas", -1),
          _createElementVNode("option", { value: "horarios" }, "Horários Ocupados", -1)
        ]), 512), [
          [_vModelSelect, _ctx.selectedReport]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.generateReport && _ctx.generateReport(...args))),
          disabled: _ctx.loading
        }, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "⏳ Gerando..."))
            : (_openBlock(), _createElementBlock("span", _hoisted_7, "📥 Baixar Relatório"))
        ], 8, _hoisted_5)
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[4] || (_cache[4] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Processando relatório...", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}