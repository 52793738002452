import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vModelSelect as _vModelSelect, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "search-section" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 1,
  class: "loader-container"
}
const _hoisted_5 = {
  key: 2,
  class: "user-dropdown"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 3,
  class: "no-results"
}
const _hoisted_8 = { class: "clubinho-type-section" }
const _hoisted_9 = { class: "dates-section" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_12 = {
  key: 1,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "create-clubinho-page",
    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/adm-dashboard"
    }),
    _createElementVNode("div", _hoisted_1, [
      _cache[12] || (_cache[12] = _createElementVNode("h3", null, "CRIAR NOVO CLUBINHO", -1)),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
          disabled: !!_ctx.selectedUser,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDropdown = !_ctx.selectedUser)),
          placeholder: "Digite o nome ou email do usuário",
          class: "search-input"
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.searchQuery]
        ]),
        (_ctx.selectedUser)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "change-user-btn",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearSelectedUser && _ctx.clearSelectedUser(...args)))
            }, " Trocar Usuário "))
          : _createCommentVNode("", true),
        (_ctx.loadingUsers)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[7] || (_cache[7] = [
              _createElementVNode("div", { class: "loader" }, null, -1),
              _createElementVNode("p", null, "Carregando usuários...", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.showDropdown && !_ctx.loadingUsers && !_ctx.selectedUser && _ctx.filteredUsers.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredUsers, (userItem) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: userItem.id,
                  onClick: _withModifiers(($event: any) => (_ctx.selectUser(userItem)), ["stop"])
                }, _toDisplayString(userItem.nome) + " - " + _toDisplayString(userItem.email), 9, _hoisted_6))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingUsers && !_ctx.selectedUser && _ctx.searchQuery.trim().length > 0 && _ctx.filteredUsers.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, " Nenhum usuário encontrado. "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[9] || (_cache[9] = _createElementVNode("h4", null, "Tipo de Clubinho:", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.clubinhoTipo) = $event)),
          class: "clubinho-select"
        }, _cache[8] || (_cache[8] = [
          _createStaticVNode("<option disabled value=\"\" data-v-7cfa05e4>Selecione o tipo</option><option value=\"4\" data-v-7cfa05e4>Individual</option><option value=\"3\" data-v-7cfa05e4>Família</option><option value=\"5\" data-v-7cfa05e4>Estudante</option><option value=\"6\" data-v-7cfa05e4>Aluno</option>", 5)
        ]), 512), [
          [_vModelSelect, _ctx.clubinhoTipo]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[10] || (_cache[10] = _createElementVNode("h4", null, "Datas", -1)),
        _createElementVNode("p", null, "Data Início (hoje): " + _toDisplayString(_ctx.formatDate(_ctx.dataInicio)), 1),
        _createElementVNode("p", null, "Data Fim (máximo 1 mês depois): " + _toDisplayString(_ctx.formatDate(_ctx.dataFim)), 1)
      ]),
      _createElementVNode("button", {
        class: "create-button",
        disabled: !_ctx.isFormValid,
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.createClubinho && _ctx.createClubinho(...args)))
      }, " Criar Clubinho ", 8, _hoisted_10),
      (_ctx.creating)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[11] || (_cache[11] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Criando o clubinho...", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}