import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "register-container" }
const _hoisted_2 = { class: "register-box" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  key: 0,
  class: "error"
}
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/login"
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("h1", null, "Cadastro", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Enviando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendConfirmationEmail && _ctx.sendConfirmationEmail(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("label", { for: "email" }, "Email", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                id: "email",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.email) = $event)),
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('email')))
              }, null, 544), [
                [_vModelText, _ctx.state.email]
              ]),
              (_ctx.state.errors.email)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.state.errors.email), 1))
                : _createCommentVNode("", true)
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("button", { type: "submit" }, "Enviar Email de Confirmação", -1))
          ], 32)),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _cache[7] || (_cache[7] = _createTextVNode(" Já tem uma conta? ")),
            _createVNode(_component_router_link, { to: "/login" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Faça seu login")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}