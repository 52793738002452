import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "about-page" }
const _hoisted_2 = { class: "overlay" }
const _hoisted_3 = { class: "carousel-section" }
const _hoisted_4 = { class: "carousel-border" }
const _hoisted_5 = { class: "carousel-slide" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "carousel-caption" }
const _hoisted_8 = { class: "testimonials" }
const _hoisted_9 = { class: "testimonial-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("section", { class: "introduction" }, [
          _createElementVNode("h1", null, "Bem-vindo à SUNSET ARENA LAVRAS"),
          _createElementVNode("div", { class: "start" }, [
            _createElementVNode("h2", null, "Muito mais do que uma arena, um estilo de vida!"),
            _createElementVNode("p", null, " Localizada no coração de Lavras, a Sunset Arena é o ponto de encontro perfeito para quem busca esporte, lazer e bem-estar. Com cinco quadras de areia, incluindo duas cobertas, oferecemos a estrutura ideal para atletas, amigos e famílias se conectarem através do esporte. Além das quadras, contamos com o Açaí OAKBERRY, o Restaurante DUNA e um amplo estacionamento, garantindo conforto e conveniência para a sua experiência. Fundada em 2024, nossa missão é promover o esporte, a saúde e a amizade, criando momentos inesquecíveis em um ambiente vibrante e acolhedor. Venha viver essa experiência com a gente! ")
          ])
        ], -1)),
        _createElementVNode("section", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Swiper, {
              modules: [_ctx.Autoplay, _ctx.Navigation, _ctx.Pagination],
              "slides-per-view": 1,
              loop: true,
              autoplay: { delay: 3000, disableOnInteraction: false },
              navigation: "",
              pagination: { clickable: true },
              class: "mySwiper"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselImages, (image, index) => {
                  return (_openBlock(), _createBlock(_component_SwiperSlide, { key: index }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("img", {
                          src: image,
                          alt: "Imagem do Carrossel"
                        }, null, 8, _hoisted_6),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("h3", null, "Quadra " + _toDisplayString(index + 1), 1)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["modules"])
          ])
        ]),
        _createElementVNode("section", _hoisted_8, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", null, "O que Nossos Clientes Dizem", -1)),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testimonials, (testimonial) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "testimonial",
                key: testimonial.id
              }, [
                _createElementVNode("p", null, "\"" + _toDisplayString(testimonial.mensagem) + "\"", 1),
                _createElementVNode("h4", null, "- " + _toDisplayString(testimonial.nome), 1)
              ]))
            }), 128))
          ])
        ]),
        _cache[2] || (_cache[2] = _createStaticVNode("<section id=\"refund-policy\" class=\"refund-policy\" data-v-6a3a28e1><h2 data-v-6a3a28e1>Política de Reembolso</h2><div class=\"policy-content\" data-v-6a3a28e1><h3 data-v-6a3a28e1>Cancelamentos e Reembolsos</h3><p id=\"p1\" data-v-6a3a28e1> Entendemos que imprevistos acontecem. Oferecemos cancelamento gratuito até 24 horas antes da data da reserva ou até 7 dias após a compra, o que ocorrer primeiro. Além disso, em caso de chuva, o cancelamento também será gratuito. Reembolsos serão processados para reservas canceladas dentro desses períodos. </p><h3 data-v-6a3a28e1>Como Solicitar um Reembolso</h3><p data-v-6a3a28e1> Para solicitar um reembolso, entre em contato conosco através do e-mail: <strong data-v-6a3a28e1>sunsetarenainfo@gmail.com</strong>. Por favor, inclua as informações da reserva e o motivo do cancelamento. </p></div></section><section class=\"payment-info\" data-v-6a3a28e1><h2 data-v-6a3a28e1>Formas de Pagamento</h2><div class=\"payment-content\" data-v-6a3a28e1><p data-v-6a3a28e1> No site, os pagamentos só podem ser realizados via cartão. Entretanto, ao entrar em contato conosco por WhatsApp, Instagram ou e-mail, você também poderá optar pelo pagamento via PIX ou cartão, e para pagamentos em dinheiro, estes serão aceitos somente presencialmente. </p></div></section>", 2))
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}