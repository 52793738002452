<template>
  <div class="employee-dashboard">
    <HomeIcon :showArrow="true" to="/account" />
    <h2>Painel de Controle de Funcionários</h2>
    <div class="button-group">
      <router-link to="/employee-horarios" class="dashboard-button">
        <span class="button-title">Ver Horários Ocupados</span>
        <span class="button-desc">Consulte os horários reservados e disponibilidade</span>
      </router-link>

      <router-link to="/employee-searchClients" class="dashboard-button">
        <span class="button-title">Buscar Usuário</span>
        <span class="button-desc">Pesquise e visualize informações dos usuários</span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'EmployeeDashboard',
  components: {
    HomeIcon
  }
});
</script>

<style scoped>
.employee-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  min-height: 100vh;
  background: linear-gradient(135deg, #34D399 0%, #059669 100%);
  text-align: center;
}

h2 {
  font-size: 2.4rem;
  color: #ffffff;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.button-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  max-width: 800px;
  width: 100%;
}

/* Botões em formato de "cards" */
.dashboard-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 12px;
  font-size: 1.2rem;
  color: #ffffff;
  text-decoration: none;
  border-radius: 12px;
  font-weight: bold;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  text-align: center;
}

.dashboard-button:hover {
  transform: translateY(-4px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2));
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Título e descrição dentro do botão */
.button-title {
  font-size: 1.2rem;
  margin-bottom: 6px;
}

.button-desc {
  font-size: 0.9rem;
  font-weight: bold; /* Aumenta a espessura para combinar com o painel anterior */
  text-transform: none;
  color: #e0e0e0;
  max-width: 200px;
  line-height: 1.3;
  text-align: center;
}

@media (max-width: 600px) {
  .button-group {
    grid-template-columns: 1fr;
  }

  .dashboard-button {
    font-size: 1.1rem;
    padding: 16px;
  }

  .button-title {
    font-size: 1.1rem;
  }

  .button-desc {
    font-size: 0.85rem;
  }
}
</style>
