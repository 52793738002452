import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["max"]
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "schedule-grid" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "time-info" }
const _hoisted_8 = {
  key: 0,
  class: "reservation-info"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = {
  key: 0,
  class: "blocked-times-section"
}
const _hoisted_12 = {
  key: 1,
  class: "no-blocked-times"
}
const _hoisted_13 = {
  key: 2,
  class: "modal-overlay"
}
const _hoisted_14 = { class: "modal-container" }
const _hoisted_15 = { class: "button-group" }
const _hoisted_16 = { class: "reservation-form-container" }
const _hoisted_17 = { class: "selected-hours-container" }
const _hoisted_18 = {
  key: 0,
  class: "no-selected-hours"
}
const _hoisted_19 = { class: "selected-hours" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = { class: "input-with-button" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = {
  key: 0,
  class: "loader-container-clients"
}
const _hoisted_25 = {
  key: 1,
  class: "client-dropdown"
}
const _hoisted_26 = ["onClick"]
const _hoisted_27 = {
  key: 2,
  class: "no-results"
}
const _hoisted_28 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "court-schedules",
    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", null, "Horários para " + _toDisplayString(_ctx.formattedDate), 1),
      _withDirectives(_createElementVNode("input", {
        type: "date",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDateInput) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fetchSchedules && _ctx.fetchSchedules(...args))),
        max: _ctx.maxDate
      }, null, 40, _hoisted_2), [
        [_vModelText, _ctx.selectedDateInput]
      ]),
      (_ctx.loadingSchedules)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[10] || (_cache[10] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courts, (court) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: court.id,
                  class: "court-section"
                }, [
                  _createElementVNode("h4", null, _toDisplayString(court.nome), 1),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimes[court.id], (time) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: time.hour,
                        class: _normalizeClass({
                    stripe: time.pagamentoPorStripe,
                    available: time.available,
                    reserved: time.reservadoPor,
                    unavailable: !time.available && !time.reservadoPor,
                    selected: _ctx.isSelected(court.id, time.hour)
                  }),
                        onClick: ($event: any) => (time.available ? _ctx.selectHour(court.id, court.nome, time.hour, time.available) : null)
                      }, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(time.hour), 1),
                        (!time.available && time.reservadoPor)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("strong", null, _toDisplayString(time.reservadoPor || 'Indefinido'), 1),
                              (!time.isPast)
                                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                    key: 0,
                                    icon: "trash",
                                    class: _normalizeClass({
                    iconTrash: !time.pagamentoPorStripe,
                    iconTrashStripe: time.pagamentoPorStripe
                  }),
                                    onClick: _withModifiers(($event: any) => (_ctx.confirmDeleteHour(time.id, time.hour)), ["stop"])
                                  }, null, 8, ["class", "onClick"]))
                                : _createCommentVNode("", true)
                            ]))
                          : (!time.available && !time.reservadoPor)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Indisponível"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_10, "Disponível"))
                      ], 10, _hoisted_6))
                    }), 128))
                  ])
                ]))
              }), 128))
            ]),
            (_ctx.blockedTimes.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _cache[11] || (_cache[11] = _createElementVNode("h4", null, "Horários Bloqueados", -1)),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blockedTimes, (blocked) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `${blocked.court}-${blocked.hour}`
                      }, " Quadra: " + _toDisplayString(blocked.court) + " - Horário: " + _toDisplayString(blocked.hour) + " - Usuário: " + _toDisplayString(blocked.user), 1))
                    }), 128))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[12] || (_cache[12] = [
                  _createElementVNode("p", null, "Nenhuma sessão em andamento.", -1)
                ]))),
            (_ctx.showConfirmation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[13] || (_cache[13] = _createElementVNode("h2", null, "Confirmar Exclusão", -1)),
                    _cache[14] || (_cache[14] = _createElementVNode("p", null, "Deseja realmente excluir este horário?", -1)),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("button", {
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancelDeleteHour && _ctx.cancelDeleteHour(...args))),
                        class: "modal-button cancel"
                      }, "Cancelar"),
                      _createElementVNode("button", {
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args))),
                        class: "modal-button confirm"
                      }, "Continuar")
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_16, [
              _cache[18] || (_cache[18] = _createElementVNode("h3", null, "Adicionar Reserva", -1)),
              _createElementVNode("div", _hoisted_17, [
                _cache[15] || (_cache[15] = _createElementVNode("h4", null, "Quadras e Horários Selecionados", -1)),
                (_ctx.selectedHours.length === 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_18, "Nenhum horário selecionado"))
                  : _createCommentVNode("", true),
                _createElementVNode("ul", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedHours, (hour, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _createTextVNode(_toDisplayString(hour.courtName) + " - " + _toDisplayString(hour.hour) + " ", 1),
                      _createElementVNode("span", {
                        class: "remove",
                        onClick: ($event: any) => (_ctx.removeHour(index))
                      }, [
                        _createVNode(_component_font_awesome_icon, { icon: "trash" })
                      ], 8, _hoisted_20)
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { for: "client" }, "Cliente", -1)),
                _createElementVNode("div", _hoisted_22, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchQuery) = $event)),
                    onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
                    disabled: _ctx.selectedClient !== null,
                    onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDropdown = true)),
                    placeholder: "Digite o nome ou email do cliente",
                    class: "form-control"
                  }, null, 40, _hoisted_23), [
                    [_vModelText, _ctx.searchQuery]
                  ]),
                  (_ctx.selectedClient)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.clearClient && _ctx.clearClient(...args))),
                        class: "btn-change-client"
                      }, " Alterar Cliente "))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.loadingClients)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[16] || (_cache[16] = [
                      _createElementVNode("div", { class: "loader-clients" }, null, -1),
                      _createElementVNode("p", null, "Carregando dados...", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_ctx.showDropdown && !_ctx.loadingClients && _ctx.filteredClients.length > 0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_25, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredClients, (client) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: client.id,
                          onClick: ($event: any) => (_ctx.selectClient(client))
                        }, _toDisplayString(client.nome) + " - " + _toDisplayString(client.email), 9, _hoisted_26))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.loadingClients && _ctx.searchQuery.trim().length > 0 && _ctx.filteredClients.length === 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_27, " Nenhum cliente encontrado. "))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("button", {
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.saveReservation && _ctx.saveReservation(...args))),
                class: "btn-save",
                disabled: !_ctx.selectedClient || _ctx.selectedHours.length === 0
              }, " Salvar Reserva ", 8, _hoisted_28)
            ])
          ]))
    ])
  ]))
}