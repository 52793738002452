import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "account" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "help-text" }
const _hoisted_9 = { class: "user-info" }
const _hoisted_10 = ["readonly"]
const _hoisted_11 = {
  key: 0,
  class: "error"
}
const _hoisted_12 = {
  key: 0,
  class: "error"
}
const _hoisted_13 = {
  key: 0,
  class: "error"
}
const _hoisted_14 = ["readonly"]
const _hoisted_15 = {
  key: 0,
  class: "error"
}
const _hoisted_16 = { class: "button-group" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = {
  key: 0,
  class: "extra-buttons"
}
const _hoisted_24 = { class: "horarios-section" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { class: "planos-section" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "plano-info" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { key: 2 }
const _hoisted_39 = ["onClick", "disabled", "title"]
const _hoisted_40 = ["onClick", "disabled", "title"]
const _hoisted_41 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[16] || (_cache[16] = _createElementVNode("h2", null, "Minha Conta", -1)),
        _createVNode(_component_font_awesome_icon, {
          icon: _ctx.isEditing ? 'times' : 'edit',
          class: _normalizeClass(["edit-icon", { active: _ctx.isEditing }]),
          onClick: _ctx.toggleEdit
        }, null, 8, ["icon", "onClick", "class"])
      ]),
      (_ctx.loading || _ctx.loadingLogout)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "loader" }, null, -1)),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Carregando dados..."))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, "Processando logout..."))
          ]))
        : (_ctx.user)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[18] || (_cache[18] = _createTextVNode(" Clique no ícone ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.isEditing ? "X" : "editar"), 1),
                _createTextVNode(" no canto superior direito para " + _toDisplayString(_ctx.isEditing ? "sair do modo de edição" : "entrar no modo de edição") + ". ", 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", null, [
                  _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Nome:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    readonly: !_ctx.isEditing,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.nome) = $event)),
                    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('nome'))),
                    onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validateField('nome'))),
                    maxlength: "30",
                    placeholder: "Digite seu nome"
                  }, null, 40, _hoisted_10), [
                    [_vModelText, _ctx.user.nome]
                  ]),
                  (_ctx.errors.nome)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.errors.nome), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("p", null, [
                  _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Email:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    readonly: true,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.email) = $event)),
                    maxlength: "50",
                    placeholder: "Digite seu email"
                  }, null, 512), [
                    [_vModelText, _ctx.user.email]
                  ]),
                  (_ctx.errors.email)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.errors.email), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("p", null, [
                  _cache[21] || (_cache[21] = _createElementVNode("strong", null, "CPF:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    readonly: true,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.cpf) = $event)),
                    onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validateField('cpf'))),
                    onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.applyCpfMask && _ctx.applyCpfMask(...args))),
                    maxlength: "14",
                    placeholder: "XXX.XXX.XXX-XX"
                  }, null, 544), [
                    [_vModelText, _ctx.user.cpf]
                  ]),
                  (_ctx.errors.cpf)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.errors.cpf), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("p", null, [
                  _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Telefone:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    readonly: !_ctx.isEditing,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.telefone) = $event)),
                    onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.validateField('telefone'))),
                    onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.applyPhoneMask && _ctx.applyPhoneMask(...args))),
                    maxlength: "15",
                    placeholder: "(XX) XXXXX-XXXX"
                  }, null, 40, _hoisted_14), [
                    [_vModelText, _ctx.user.telefone]
                  ]),
                  (_ctx.errors.telefone)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.errors.telefone), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                (_ctx.isEditing)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.saveUser && _ctx.saveUser(...args))),
                      class: "save-button",
                      disabled: _ctx.saving
                    }, [
                      (_ctx.saving)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Salvando..."))
                        : (_openBlock(), _createElementBlock("span", _hoisted_19, "Salvar"))
                    ], 8, _hoisted_17))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
                      class: "logout-button",
                      disabled: _ctx.loadingLogout
                    }, [
                      (_ctx.loadingLogout)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Saindo..."))
                        : (_openBlock(), _createElementBlock("span", _hoisted_22, "Logout"))
                    ], 8, _hoisted_20))
              ]),
              (!_ctx.isEditing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (_ctx.isEmployee)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.navigateToEmployeePage && _ctx.navigateToEmployeePage(...args))),
                          class: "employee-button"
                        }, " Página de Funcionários "))
                      : _createCommentVNode("", true),
                    (_ctx.isAdm)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.navigateToAdmPage && _ctx.navigateToAdmPage(...args))),
                          class: "employee-button"
                        }, " Página de ADM "))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", {
                      onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.toggleHorarios && _ctx.toggleHorarios(...args))),
                      class: "horarios-button"
                    }, _toDisplayString(_ctx.showHorarios ? "Ocultar Horários" : "Meus Horários"), 1),
                    _createElementVNode("button", {
                      onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.togglePlanos && _ctx.togglePlanos(...args))),
                      class: "planos-button"
                    }, _toDisplayString(_ctx.showPlanos ? "Ocultar Planos" : "Meus Planos"), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_Transition, { name: "fade-slide" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", _hoisted_24, [
                    _cache[30] || (_cache[30] = _createElementVNode("h3", null, "MEUS HORÁRIOS", -1)),
                    (_ctx.loadingHorarios)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[23] || (_cache[23] = [
                          _createElementVNode("div", { class: "loader" }, null, -1),
                          _createElementVNode("p", null, "Carregando dados...", -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          (_ctx.horarios.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.horarios, (horario) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: horario.id,
                                    class: "horario"
                                  }, [
                                    _createElementVNode("p", null, [
                                      _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Data:", -1)),
                                      _createTextVNode(" " + _toDisplayString(_ctx.formatDate(horario.data)), 1)
                                    ]),
                                    _createElementVNode("p", null, [
                                      _cache[25] || (_cache[25] = _createElementVNode("strong", null, "Hora:", -1)),
                                      _createTextVNode(" " + _toDisplayString(horario.horario), 1)
                                    ]),
                                    _createElementVNode("p", null, [
                                      _cache[26] || (_cache[26] = _createElementVNode("strong", null, "Quadra:", -1)),
                                      _createTextVNode(" " + _toDisplayString(horario.quadra.nome), 1)
                                    ]),
                                    (horario.idReserva != null)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_28, [
                                          _cache[27] || (_cache[27] = _createElementVNode("strong", null, "Parte do Plano:", -1)),
                                          _createTextVNode(" " + _toDisplayString(horario.idReserva), 1)
                                        ]))
                                      : (_openBlock(), _createElementBlock("p", _hoisted_29, _cache[28] || (_cache[28] = [
                                          _createElementVNode("strong", null, "Avulso", -1)
                                        ])))
                                  ]))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[29] || (_cache[29] = [
                                _createElementVNode("p", null, "Não há nenhum horário.", -1)
                              ])))
                        ]))
                  ], 512), [
                    [_vShow, _ctx.showHorarios && !_ctx.isEditing]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_Transition, { name: "fade-slide" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", _hoisted_31, [
                    _cache[39] || (_cache[39] = _createElementVNode("h3", null, "MEUS PLANOS", -1)),
                    (_ctx.loadingPlanos)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[31] || (_cache[31] = [
                          _createElementVNode("div", { class: "loader" }, null, -1),
                          _createElementVNode("p", null, "Carregando dados...", -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_33, [
                          (_ctx.planos.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planos, (plano) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: plano.id,
                                    class: "plano"
                                  }, [
                                    _createElementVNode("div", _hoisted_35, [
                                      _createElementVNode("p", null, [
                                        _createElementVNode("strong", null, _toDisplayString(plano.tipo) + " " + _toDisplayString(plano.id), 1)
                                      ]),
                                      _createElementVNode("p", null, [
                                        _cache[32] || (_cache[32] = _createElementVNode("strong", null, "Nome:", -1)),
                                        _createTextVNode(" " + _toDisplayString(plano.nome), 1)
                                      ]),
                                      _createElementVNode("p", null, [
                                        _cache[33] || (_cache[33] = _createElementVNode("strong", null, "Início:", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.formatDate(plano.dataInicio)), 1)
                                      ]),
                                      _createElementVNode("p", null, [
                                        _cache[34] || (_cache[34] = _createElementVNode("strong", null, "Fim:", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.formatDate(plano.dataFim)), 1)
                                      ]),
                                      (plano.tipo === 'Plano')
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_36, [
                                            _cache[35] || (_cache[35] = _createElementVNode("strong", null, "Quadra: ", -1)),
                                            _createTextVNode(" " + _toDisplayString(plano.quadra?.nome || "Indefinido"), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (plano.tipo === 'Plano')
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_37, [
                                            _cache[36] || (_cache[36] = _createElementVNode("strong", null, "Dia da Semana:", -1)),
                                            _createTextVNode(" " + _toDisplayString(_ctx.getDiaSemana(plano.indexDia!)), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (plano.tipo === 'Plano')
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_38, [
                                            _cache[37] || (_cache[37] = _createElementVNode("strong", null, "Horários:", -1)),
                                            _createTextVNode(" " + _toDisplayString(plano.horarios), 1)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    (plano.tipo === 'Plano')
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          onClick: ($event: any) => (_ctx.renewPlan(plano.id)),
                                          disabled: !_ctx.isRenewalAvailable(plano.dataFim),
                                          title: _ctx.isRenewalAvailable(plano.dataFim) ? 'Disponível para renovação' : 'Indisponível para renovação',
                                          class: _normalizeClass(["renew-button", { 'available': _ctx.isRenewalAvailable(plano.dataFim) }])
                                        }, " Renovar Plano ", 10, _hoisted_39))
                                      : _createCommentVNode("", true),
                                    (plano.tipo === 'Clubinho')
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          onClick: ($event: any) => (_ctx.renewClubinho(plano.id)),
                                          disabled: !_ctx.isRenewalAvailable(plano.dataFim),
                                          title: _ctx.isRenewalAvailable(plano.dataFim) ? 'Disponível para renovação' : 'Indisponível para renovação',
                                          class: _normalizeClass(["renew-button clubinho", { 'available': _ctx.isRenewalAvailable(plano.dataFim) }])
                                        }, " Renovar Plano ", 10, _hoisted_40))
                                      : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_41, _cache[38] || (_cache[38] = [
                                _createElementVNode("p", null, "Não há planos ativos.", -1)
                              ])))
                        ]))
                  ], 512), [
                    [_vShow, _ctx.showPlanos && !_ctx.isEditing]
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}