<template>
  <div class="privacy-policy-container">
    <h1>Política de Privacidade</h1>
    <div class="content">
      <section>
        <h2>1. Controlador dos Dados</h2>
        <p>
          Esta política se aplica aos dados pessoais coletados e processados por
          <strong>Sunset Arena Lavras</strong>, com sede em Lavras, Minas Gerais,
          Brasil.
        </p>
      </section>

      <section>
        <h2>2. Introdução</h2>
        <p>
          Respeitamos sua privacidade e estamos comprometidos em proteger suas informações pessoais.
          Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e compartilhamos
          suas informações ao utilizar nossa plataforma de aluguel de quadras de areia.
        </p>
      </section>

      <section>
        <h2>3. Informações que Coletamos</h2>
        <p>
          Coletamos informações pessoais que você nos fornece diretamente ao se cadastrar ou usar nossa
          plataforma, incluindo:
        </p>
        <ul>
          <li><strong>Nome completo:</strong> Utilizado para personalizar sua conta e facilitar sua identificação visual na plataforma.</li>
          <li>
            <strong>CPF:</strong> Necessário para cadastro e garantir a autenticidade do seu cadastro.
          </li>
          <li><strong>Email:</strong> Utilizado para comunicação e recuperação de acesso.</li>
          <li>
            <strong>Senha:</strong> Armazenada de forma criptografada utilizando técnicas seguras de hashing.
          </li>
          <li>
            <strong>Telefone:</strong> Utilizado para contato em casos de necessidade sobre reservas.
          </li>
        </ul>
        <p>
          Também coletamos automaticamente informações técnicas, como endereço IP, tipo de navegador,
          dispositivo e dados de cookies, estritamente para autenticação e manutenção da sessão.
        </p>
      </section>

      <section>
        <h2>4. Uso de Informações</h2>
        <p>
          Utilizamos suas informações pessoais para as seguintes finalidades:
        </p>
        <ul>
          <li>Gerenciamento do seu cadastro e acesso à plataforma.</li>
          <li>Processamento e confirmação de reservas de quadras.</li>
          <li>Comunicação sobre atualizações, promoções ou mudanças nos termos.</li>
          <li>Atendimento a obrigações legais e regulamentares.</li>
          <li>Garantia de segurança e prevenção de fraudes.</li>
        </ul>
      </section>

      <section>
        <h2>5. Base Legal</h2>
        <p>
          O tratamento dos seus dados pessoais é realizado com base em:
        </p>
        <ul>
          <li>Consentimento do titular.</li>
          <li>Execução de contrato ou procedimentos preliminares relacionados a um contrato.</li>
          <li>Cumprimento de obrigação legal ou regulatória.</li>
          <li>
            Legítimo interesse do Controlador, quando aplicável, sempre respeitando os direitos e
            liberdades fundamentais do titular.
          </li>
        </ul>
      </section>

      <section>
        <h2>6. Compartilhamento de Informações</h2>
        <p>
          Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para:
        </p>
        <ul>
          <li>
            Processar pagamentos por meio de plataformas seguras e certificadas.
          </li>
          <li>
            Cumprir obrigações legais ou responder a ordens judiciais.
          </li>
          <li>
            Proteger nossos direitos, propriedade ou a segurança dos nossos usuários.
          </li>
        </ul>
        <p>
          Seus dados não serão vendidos ou comercializados para fins de marketing.
        </p>
      </section>

      <section>
        <h2>7. Uso de Cookies</h2>
        <p>
          Utilizamos cookies exclusivamente para gerenciar sua sessão de login e garantir que sua experiência
          na plataforma seja segura e contínua. Esses cookies são empregados apenas para autenticação e não coletam
          informações adicionais além das necessárias para o funcionamento da sessão.
        </p>
        <p>
          Você pode controlar o uso de cookies nas configurações do seu navegador, mas a desativação pode afetar
          a sua capacidade de se manter logado na plataforma.
        </p>
      </section>

      <section>
        <h2>8. Segurança das Informações</h2>
        <p>
          Adotamos medidas técnicas e organizacionais robustas para proteger seus dados, tais como:
        </p>
        <ul>
          <li>
            Criptografia de dados sensíveis, como senhas, utilizando técnicas de hashing seguro.
          </li>
          <li>
            Uso de protocolos HTTPS para comunicação segura entre o usuário e a plataforma.
          </li>
          <li>
            Monitoramento contínuo e auditorias para identificar e mitigar vulnerabilidades.
          </li>
        </ul>
      </section>

      <section>
        <h2>9. Direitos dos Usuários</h2>
        <p>
          Em conformidade com a LGPD, você possui os seguintes direitos em relação aos seus dados pessoais:
        </p>
        <ul>
          <li>
            <strong>Acesso:</strong> Solicitar informações sobre os dados que armazenamos sobre você.
          </li>
          <li>
            <strong>Correção:</strong> Solicitar a correção de dados imprecisos ou incompletos.
          </li>
          <li>
            <strong>Exclusão:</strong> Solicitar a exclusão dos seus dados, salvo quando houver obrigação legal de retê-los.
          </li>
          <li>
            <strong>Portabilidade:</strong> Solicitar a transferência dos seus dados para outro fornecedor de serviços.
          </li>
          <li>
            <strong>Revogação de Consentimento:</strong> Retirar seu consentimento para o uso dos seus dados a qualquer momento.
          </li>
        </ul>
        <p>
          Para exercer esses direitos, entre em contato conosco através dos canais informados na seção de Contato.
        </p>
      </section>

      <section>
        <h2>10. Alterações nesta Política de Privacidade</h2>
        <p>
          Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos alterações significativas
          por meio de avisos em nossa plataforma ou por email. Recomendamos que você revise esta política regularmente
          para se manter informado sobre as práticas de proteção de dados.
        </p>
      </section>

      <section>
        <h2>11. Contato</h2>
        <p>
          Se você tiver dúvidas, preocupações ou solicitações relacionadas a esta Política de Privacidade, entre em
          contato conosco pelo email: sunsetarenainfo@gmail.com.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
};
</script>

<style scoped>
.privacy-policy-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

h1, h2 {
  color: #333;
}

p, ul {
  font-size: 16px;
  line-height: 1.6;
}

section {
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>
