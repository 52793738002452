import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "schedule-page" }
const _hoisted_2 = { class: "overlay" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = {
  key: 1,
  class: "teachers"
}
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Agendamento de Aulas", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Escolha seu professor e agende sua aula via WhatsApp", -1)),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "loader" }, null, -1),
              _createElementVNode("p", null, "Carregando dados...", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teachers, (teacher) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: teacher.id,
                  class: "teacher"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(teacher.nome), 1),
                  _createElementVNode("h4", null, _toDisplayString(teacher.especialidade), 1),
                  _createElementVNode("p", null, _toDisplayString(teacher.descricao), 1),
                  _createElementVNode("a", {
                    href: `https://wa.me/55${teacher.telefone}?text=Olá,%20gostaria%20de%20agendar%20uma%20aula%20de%20${teacher.especialidade}`,
                    target: "_blank"
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("button", null, "AGENDAR AULA VIA WHATSAPP", -1)
                  ]), 8, _hoisted_5)
                ]))
              }), 128))
            ]))
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}