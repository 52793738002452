import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "sign-up-page" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/clubinho"
    }),
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitSignUp && _ctx.submitSignUp(...args)), ["prevent"])),
      class: "form-container"
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("h1", null, "Envie Sua Comprovação de Estudante", -1)),
      _cache[12] || (_cache[12] = _createElementVNode("p", { class: "description" }, " Envie seus documentos para análise da equipe Sunset Arena. Assim que confirmarmos as informações, entraremos em contato por e-mail ou WhatsApp para finalizar sua assinatura do Clubinho. ", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Enviando dados...", -1)
          ])))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[6] || (_cache[6] = _createElementVNode("label", { for: "nome" }, "Nome", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.nome) = $event)),
                type: "text",
                id: "nome",
                disabled: ""
              }, null, 512), [
                [_vModelText, _ctx.form.nome]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[7] || (_cache[7] = _createElementVNode("label", { for: "email" }, "E-mail", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
                type: "email",
                id: "email",
                disabled: ""
              }, null, 512), [
                [_vModelText, _ctx.form.email]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[8] || (_cache[8] = _createElementVNode("label", { for: "telefone" }, "Telefone", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.telefone) = $event)),
                type: "text",
                id: "telefone",
                disabled: ""
              }, null, 512), [
                [_vModelText, _ctx.form.telefone]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[9] || (_cache[9] = _createElementVNode("label", { for: "arquivo" }, "Documento", -1)),
              _createElementVNode("input", {
                type: "file",
                id: "arquivo",
                onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
                accept: "*/*",
                required: ""
              }, null, 32),
              (_ctx.form.errors.arquivo)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.form.errors.arquivo), 1))
                : _createCommentVNode("", true)
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("button", {
              type: "submit",
              class: "button"
            }, "Enviar", -1))
          ]))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}