import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "complete-registration-container" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = {
  key: 1,
  class: "errorMain"
}
const _hoisted_4 = {
  key: 2,
  class: "complete-registration-box"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 0,
  class: "error"
}
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  key: 0,
  class: "error"
}
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "error"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "error"
}
const _hoisted_13 = { class: "form-group checkbox-group" }
const _hoisted_14 = {
  key: 0,
  class: "error"
}
const _hoisted_15 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[13] || (_cache[13] = [
          _createElementVNode("div", { class: "loader" }, null, -1),
          _createElementVNode("p", null, "Carregando dados...", -1)
        ])))
      : (_ctx.errorMain)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[15] || (_cache[15] = _createElementVNode("span", null, "Erro ao validar o link. Ele pode estar expirado ou inválido.", -1)),
            _createVNode(_component_router_link, { to: "/register" }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createElementVNode("button", { class: "btn-retry" }, "Voltar para Cadastro", -1)
              ])),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[22] || (_cache[22] = _createElementVNode("h1", null, "Complete seu Cadastro", -1)),
            _createElementVNode("form", {
              onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.completeRegistration && _ctx.completeRegistration(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _cache[16] || (_cache[16] = _createElementVNode("label", { for: "nome" }, "Nome", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "nome",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.nome) = $event)),
                  onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('nome')))
                }, null, 544), [
                  [_vModelText, _ctx.state.nome]
                ]),
                (_ctx.state.errors.nome)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.state.errors.nome), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { for: "cpf" }, "CPF", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "cpf",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.cpf) = $event)),
                  onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.applyCpfMask && _ctx.applyCpfMask(...args))),
                  onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateField('cpf')))
                }, null, 544), [
                  [_vModelText, _ctx.state.cpf]
                ]),
                (_ctx.state.errors.cpf)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.state.errors.cpf), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[18] || (_cache[18] = _createElementVNode("label", { for: "telefone" }, "Telefone", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "tel",
                  id: "telefone",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.telefone) = $event)),
                  onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.applyPhoneMask && _ctx.applyPhoneMask(...args))),
                  onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateField('telefone')))
                }, null, 544), [
                  [_vModelText, _ctx.state.telefone]
                ]),
                (_ctx.state.errors.telefone)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.state.errors.telefone), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[19] || (_cache[19] = _createElementVNode("label", { for: "senha" }, "Senha", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "password",
                  id: "senha",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.senha) = $event)),
                  onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.validateField('senha')))
                }, null, 544), [
                  [_vModelText, _ctx.state.senha]
                ]),
                (_ctx.state.errors.senha)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.state.errors.senha), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  id: "acceptedTerms",
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.acceptedTerms) = $event)),
                  onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.validateField('acceptedTerms')))
                }, null, 544), [
                  [_vModelCheckbox, _ctx.state.acceptedTerms]
                ]),
                _cache[20] || (_cache[20] = _createElementVNode("label", { for: "acceptedTerms" }, [
                  _createTextVNode(" Eu aceito a "),
                  _createElementVNode("a", {
                    href: "/privacy",
                    target: "_blank"
                  }, "Política de Privacidade"),
                  _createTextVNode(" e os "),
                  _createElementVNode("a", {
                    href: "/terms",
                    target: "_blank"
                  }, "Termos de Uso"),
                  _createTextVNode(". ")
                ], -1)),
                (_ctx.state.errors.acceptedTerms)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.state.errors.acceptedTerms), 1))
                  : _createCommentVNode("", true)
              ]),
              _cache[21] || (_cache[21] = _createElementVNode("button", { type: "submit" }, "Concluir Cadastro", -1)),
              (_ctx.state.errors.general)
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.state.errors.general), 1))
                : _createCommentVNode("", true)
            ], 32)
          ]))
  ]))
}