<template>
  <div class="clubinho-payment-page">
    <!-- Ícone para voltar -->
    <HomeIcon :showArrow="true" to="/clubinho" />

    <!-- Loader global -->
    <div class="loader-container" v-if="loading">
      <div class="loader"></div>
      <p>Carregando dados...</p>
    </div>

    <!-- Conteúdo principal -->
    <div class="content-container" v-else>
      <h1>Finalize sua Assinatura do Clubinho Família</h1>

      <p class="description">
        Parabéns por dar mais um passo para se tornar parte do Clubinho Família! 
        Clique no botão abaixo para finalizar sua assinatura e aproveitar todos os benefícios exclusivos para você e sua família.
      </p>

      <div class="details">
        <p><span>Plano:</span> Clubinho Família</p>
        <p><span>Início:</span> {{ startDate }}</p>
        <p><span>Fim:</span> {{ endDate }}</p>
      </div>

      <!-- Botão que abre o modal de confirmação -->
      <button @click="showPaymentModal = true" class="button">Ir para o Pagamento</button>
    </div>

    <!-- Modal de Confirmação -->
    <div v-if="showPaymentModal" class="modal-overlay">
      <div class="modal-container">
        <h2>Atenção!</h2>
        <p>
          Confira sua escolha antes de prosseguir com o pagamento.
        </p>
        <p>
          Você pode pagar via Stripe (cartão) ou via PIX. Para pagar via PIX, clique no botão abaixo para enviar uma mensagem para nosso WhatsApp.
        </p>
        <div class="button-group">
          <button @click="cancelPayment" class="modal-button cancel">Cancelar</button>
          <button @click="confirmPayment" class="modal-button confirm">Continuar (Stripe)</button>
          <button @click="goToWhatsapp" class="modal-button whatsapp">Pagar via PIX (WhatsApp)</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import HomeIcon from '@/components/HomeIcon.vue';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';

// Carregue sua public key do Stripe
const stripePromise = loadStripe('pk_live_51Pw6F4KJmK61rZjc8fMMEQtTQAVLpgVVK3SPeFBf96Rpp40thcF79DFSXD8F6mbfmckzQy3YATb2DcM99Mj9JSmS00xIP1C5QV');

export default defineComponent({
  name: 'ClubinhoPaymentPage',
  components: {
    HomeIcon,
  },
  setup() {
    const user = ref<any>(null);
    const startDate = ref<string>('');
    const endDate = ref<string>('');
    const loading = ref<boolean>(true);
    const showPaymentModal = ref<boolean>(false);

    // Função para criar a sessão de checkout via Stripe
    const goToPayment = async () => {
      loading.value = true;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session-clubinho`,
          {
            product: 3, // ID do plano Família
            userId: user.value.id,
            startDate: startDate.value,
            endDate: endDate.value,
          },
          { withCredentials: true }
        );
        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({ sessionId: response.data.id });
        } else {
          console.error('Erro ao inicializar o Stripe.');
          loading.value = false;
        }
      } catch (error) {
        console.error('Erro ao criar a sessão do Stripe:', error);
        loading.value = false;
      }
    };

    // Função para redirecionar para o WhatsApp para pagamento via PIX
    const goToWhatsapp = () => {
      const phone = "5535997681112";
      const message = encodeURIComponent("Olá, gostaria de pagar via PIX para o Clubinho Família.");
      window.open(`https://wa.me/${phone}?text=${message}`, "_blank");
    };

    const cancelPayment = () => {
      showPaymentModal.value = false;
    };

    const confirmPayment = () => {
      showPaymentModal.value = false;
      goToPayment();
    };

    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/account`,
          { withCredentials: true }
        );
        user.value = response.data.user;
        const today = dayjs();
        startDate.value = today.format('DD/MM/YYYY');
        endDate.value = today.add(1, 'month').format('DD/MM/YYYY');
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
        alert('Ocorreu um erro ao carregar seus dados. Você será redirecionado.');
        window.location.href = '/clubinho';
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      loading.value = true;
      fetchUserData();
    });

    return {
      user,
      startDate,
      endDate,
      loading,
      showPaymentModal,
      cancelPayment,
      confirmPayment,
      goToWhatsapp,
    };
  },
});
</script>

<style scoped>
.clubinho-payment-page {
  position: relative;
  width: 100%;
  padding: 60px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00, #f01f84);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Loader container */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}
.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.content-container {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
}

.description {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1rem;
  color: #333;
}

.details {
  margin-bottom: 20px;
  text-align: left;
}

.details p {
  font-size: 1rem;
  color: #333;
  margin: 5px 0;
  font-weight: bold;
}

.details span {
  color: #f01f84;
  font-weight: 700;
}

.button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #f01f84;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #ff5858;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-container h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-button {
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
  width: 100%;
}

.modal-button.cancel {
  background-color: #ff5c5c;
  color: #fff;
}

.modal-button.cancel:hover {
  background-color: #e04848;
}

.modal-button.confirm {
  background-color: #6200ea;
  color: #fff;
}

.modal-button.confirm:hover {
  background-color: #4b00b2;
}

.modal-button.whatsapp {
  background-color: #25D366;
  color: white;
}

.modal-button.whatsapp:hover {
  background-color: #1da851;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

/* Responsividade */
@media (max-width: 768px) {
  .clubinho-payment-page {
    padding: 40px;
  }
  .content-container {
    padding: 20px;
    width: 90%;
  }
  h1 {
    font-size: 1.5rem;
  }
  .description {
    font-size: 0.9rem;
  }
  .details p {
    font-size: 0.9rem;
  }
  .button {
    font-size: 1rem;
    padding: 10px;
  }
  .modal-container {
    width: 90%;
    max-width: 400px;
  }
}
</style>
