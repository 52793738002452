import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sponsor-container" }
const _hoisted_2 = { class: "sponsor-box" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  key: 0,
  class: "error"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  key: 0,
  class: "error"
}
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  key: 0,
  class: "error"
}
const _hoisted_10 = { class: "sponsors-list" }
const _hoisted_11 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 0,
  class: "confirmation-modal"
}
const _hoisted_17 = { class: "modal-content" }
const _hoisted_18 = { class: "modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[13] || (_cache[13] = _createElementVNode("h1", null, "Criar Patrocinador", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[8] || (_cache[8] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Enviando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createSponsor && _ctx.createSponsor(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _cache[9] || (_cache[9] = _createElementVNode("label", { for: "nome" }, "Nome", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "nome",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.nome) = $event)),
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('nome')))
              }, null, 544), [
                [_vModelText, _ctx.state.nome]
              ]),
              (_ctx.state.errors.nome)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.state.errors.nome), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "descricao" }, "Descrição", -1)),
              _withDirectives(_createElementVNode("textarea", {
                id: "descricao",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.descricao) = $event)),
                onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateField('descricao')))
              }, null, 544), [
                [_vModelText, _ctx.state.descricao]
              ]),
              (_ctx.state.errors.descricao)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.state.errors.descricao), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[11] || (_cache[11] = _createElementVNode("label", { for: "imagem" }, "Imagem", -1)),
              _createElementVNode("input", {
                type: "file",
                id: "imagem",
                onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
              }, null, 32),
              (_ctx.state.errors.imagem)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.state.errors.imagem), 1))
                : _createCommentVNode("", true)
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("button", {
              type: "submit",
              class: "button"
            }, "Criar Patrocinador", -1))
          ], 32))
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Patrocinadores Cadastrados", -1)),
      (_ctx.loadingGrid)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[14] || (_cache[14] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("table", _hoisted_12, [
            _cache[16] || (_cache[16] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Nome"),
                _createElementVNode("th", null, "Descrição"),
                _createElementVNode("th", null, "Imagem"),
                _createElementVNode("th", null, "Ações")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sponsors, (sponsor) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sponsor.id
                }, [
                  _createElementVNode("td", null, _toDisplayString(sponsor.nome), 1),
                  _createElementVNode("td", null, _toDisplayString(sponsor.descricao || 'Sem descrição'), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("img", {
                      src: sponsor.imagem,
                      alt: "Imagem do Patrocinador",
                      class: "thumbnail"
                    }, null, 8, _hoisted_13)
                  ]),
                  _createElementVNode("td", null, [
                    (_ctx.loadingDelete && _ctx.deletingSponsorId === sponsor.id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[15] || (_cache[15] = [
                          _createElementVNode("div", { class: "loader small" }, null, -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: "trash",
                            class: "icon-trash",
                            onClick: ($event: any) => (_ctx.confirmDelete(sponsor.id))
                          }, null, 8, ["onClick"])
                        ]))
                  ])
                ]))
              }), 128))
            ])
          ]))
    ]),
    (_ctx.showConfirmation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _cache[18] || (_cache[18] = _createElementVNode("h3", null, "Confirmar Exclusão", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("p", null, "Deseja realmente excluir este patrocinador?", -1)),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.cancelDelete && _ctx.cancelDelete(...args))),
                class: "btn-cancel"
              }, "Cancelar"),
              _createElementVNode("button", {
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.confirmDeleteAction && _ctx.confirmDeleteAction(...args))),
                class: "btn-confirm"
              }, "Confirmar")
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}