import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "admin-dashboard" }
const _hoisted_2 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/"
    }),
    _cache[9] || (_cache[9] = _createElementVNode("h2", null, "Painel de Controle Administrativo", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/adm-horarios",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "button-title" }, "Gerenciar Horários", -1),
          _createElementVNode("span", { class: "button-desc" }, "Crie, edite e visualize horários disponíveis", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-quadras",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "button-title" }, "Gerenciar Quadras", -1),
          _createElementVNode("span", { class: "button-desc" }, "Adicione e remova quadras", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-usuarios",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "button-title" }, "Criar e editar Usuários", -1),
          _createElementVNode("span", { class: "button-desc" }, "Adicione e remova usuários", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-patrocinadores",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "button-title" }, "Gerenciar Patrocinadores", -1),
          _createElementVNode("span", { class: "button-desc" }, "Adicione e remova patrocinadores", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-choose-plan",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("span", { class: "button-title" }, "Gerenciar Planos", -1),
          _createElementVNode("span", { class: "button-desc" }, "Crie planos mensais, trimensais e do clubinho", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-professor",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("span", { class: "button-title" }, "Gerenciar Professores", -1),
          _createElementVNode("span", { class: "button-desc" }, "Cadastre e controle professores e aulas", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-search-clients",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("span", { class: "button-title" }, "Gerenciar Usuários", -1),
          _createElementVNode("span", { class: "button-desc" }, "Pesquise e edite horários e planos relacionadas a usuários", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-data",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createElementVNode("span", { class: "button-title" }, "Gerar relatórios", -1),
          _createElementVNode("span", { class: "button-desc" }, "Gere relatórios em Excel", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adm-create-plans-livre",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createElementVNode("span", { class: "button-title" }, "Criar Planos (sem limite)", -1),
          _createElementVNode("span", { class: "button-desc" }, "Crie planos sem restições de datas", -1)
        ])),
        _: 1
      })
    ])
  ]))
}