import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "input-with-button" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  key: 0,
  class: "loader-container-clients"
}
const _hoisted_9 = {
  key: 1,
  class: "client-dropdown"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 2,
  class: "no-results"
}
const _hoisted_12 = { class: "main-content" }
const _hoisted_13 = { class: "subtitle" }
const _hoisted_14 = { class: "subtitle" }
const _hoisted_15 = {
  key: 0,
  class: "legend"
}
const _hoisted_16 = {
  key: 1,
  class: "legend"
}
const _hoisted_17 = { class: "times" }
const _hoisted_18 = ["onClick", "disabled"]
const _hoisted_19 = {
  key: 2,
  class: "blocked-times-section"
}
const _hoisted_20 = {
  key: 3,
  class: "no-blocked-times"
}
const _hoisted_21 = { class: "total-price" }
const _hoisted_22 = {
  key: 4,
  class: "warning"
}
const _hoisted_23 = {
  key: 5,
  class: "error"
}
const _hoisted_24 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_WarningData = _resolveComponent("WarningData")!

  return (_openBlock(), _createElementBlock("div", {
    class: "select-time-page",
    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-choose-plan"
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[8] || (_cache[8] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.hasSelectedDates)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _cache[18] || (_cache[18] = _createElementVNode("h1", { class: "title" }, "Selecione os horários do seu plano", -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
                        disabled: _ctx.selectedClient !== null,
                        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDropdown = _ctx.selectedClient === null)),
                        placeholder: "Busque o usuário",
                        class: "form-control"
                      }, null, 40, _hoisted_7), [
                        [_vModelText, _ctx.searchQuery]
                      ]),
                      (_ctx.selectedClient)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearClient && _ctx.clearClient(...args))),
                            class: "btn-change-client"
                          }, " Alterar Usuário "))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.loadingClients)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[9] || (_cache[9] = [
                          _createElementVNode("div", { class: "loader-clients" }, null, -1),
                          _createElementVNode("p", null, "Carregando dados...", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (_ctx.showDropdown && !_ctx.loadingClients && _ctx.filteredClients.length > 0)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredClients, (client) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: client.id,
                              onClick: ($event: any) => (_ctx.selectClient(client))
                            }, _toDisplayString(client.nome) + " - " + _toDisplayString(client.email), 9, _hoisted_10))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.loadingClients && _ctx.searchQuery.trim().length > 0 && _ctx.filteredClients.length === 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_11, " Nenhum usuário encontrado. "))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("p", _hoisted_13, [
                      _cache[10] || (_cache[10] = _createElementVNode("strong", null, "O plano vai de:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.formattedStartDate) + " até " + _toDisplayString(_ctx.formattedEndDate), 1)
                    ]),
                    _createElementVNode("p", _hoisted_14, [
                      _cache[11] || (_cache[11] = _createElementVNode("strong", null, "Dia da semana escolhido: ", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.selectedDayName), 1)
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("h2", { class: "horariosDisponiveis" }, [
                      _createElementVNode("strong", null, "Horários disponíveis:")
                    ], -1)),
                    (_ctx.isMensal)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[12] || (_cache[12] = [
                          _createElementVNode("div", { class: "legend-item" }, [
                            _createElementVNode("span", { class: "dot fifty-reais" }),
                            _createTextVNode(" R$200,00 (antes de 17:00) ")
                          ], -1),
                          _createElementVNode("div", { class: "legend-item" }, [
                            _createElementVNode("span", { class: "dot eighty-reais" }),
                            _createTextVNode(" R$300,00 (a partir de 17:00) ")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[13] || (_cache[13] = [
                          _createElementVNode("div", { class: "legend-item" }, [
                            _createElementVNode("span", { class: "dot fifty-reais" }),
                            _createTextVNode("R$780,00 para todos os horários ")
                          ], -1)
                        ]))),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedCourts, (court) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: court.id,
                        class: _normalizeClass([{ disabled: _ctx.isCourtDisabled(court.id) }, "court"])
                      }, [
                        _createElementVNode("h3", null, _toDisplayString(court.nome) + " - " + _toDisplayString(court.patrocinador.nome), 1),
                        _createElementVNode("div", _hoisted_17, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimes[court.id], (time) => {
                            return (_openBlock(), _createElementBlock("button", {
                              key: time.hour,
                              class: _normalizeClass({
    disabled: !time.available,
    selected: _ctx.isSelected(court.id, time.hour),
    'before-five-mensal': _ctx.isMensal && time.hour < '17:00', // Aplicar somente se for plano mensal e antes das 17:00
    'after-five-mensal': _ctx.isMensal && time.hour >= '17:00', // Aplicar somente se for plano mensal e após as 17:00
    'all-trimestral': !_ctx.isMensal // Aplicar se não for plano mensal (trimestral)
  }),
                              onClick: ($event: any) => (_ctx.selectTime(court.id, time.hour)),
                              disabled: !time.available
                            }, _toDisplayString(time.hour), 11, _hoisted_18))
                          }), 128))
                        ])
                      ], 2))
                    }), 128)),
                    (_ctx.blockedTimes.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _cache[14] || (_cache[14] = _createElementVNode("h4", null, "Horários Bloqueados", -1)),
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blockedTimes, (blocked) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: `${blocked.court}-${blocked.hour}`
                              }, " Quadra: " + _toDisplayString(blocked.court) + " - Horário: " + _toDisplayString(blocked.hour) + " - Usuário: " + _toDisplayString(blocked.user), 1))
                            }), 128))
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[15] || (_cache[15] = [
                          _createElementVNode("p", null, "Nenhuma sessão em andamento.", -1)
                        ]))),
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "descriptions" }, [
                      _createElementVNode("p", { class: "description" }, " É possível selecionar mais de um horário de uma vez, mas todos os horários devem ser para a mesma quadra. "),
                      _createElementVNode("p", { class: "description" }, " Cada horário selecionado corresponde a marcação para os quatro dias com desconto aplicado. ")
                    ], -1)),
                    _createElementVNode("div", _hoisted_21, " Valor total: R$ " + _toDisplayString(_ctx.totalPrice.toFixed(2)), 1),
                    _createElementVNode("button", {
                      class: _normalizeClass(["next-button", { clicked: _ctx.nextButtonClicked }]),
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToNextStep && _ctx.goToNextStep(...args))),
                      onMousedown: _cache[5] || (_cache[5] = ($event: any) => (_ctx.nextButtonClicked = true)),
                      onMouseup: _cache[6] || (_cache[6] = ($event: any) => (_ctx.nextButtonClicked = false))
                    }, " Próximo ", 34),
                    (_ctx.showWarning)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_22, "Por favor, selecione um horário antes de prosseguir."))
                      : _createCommentVNode("", true),
                    (_ctx.errorMessage)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(_ctx.errorMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createVNode(_component_WarningData, { rota: _ctx.rota }, null, 8, ["rota"])
                ]))
          ]))
    ])
  ]))
}