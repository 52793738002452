import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "create-court-page" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  key: 0,
  class: "error"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  key: 0,
  class: "error"
}
const _hoisted_9 = { class: "courts-list" }
const _hoisted_10 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  class: "confirmation-modal"
}
const _hoisted_15 = { class: "modal-content" }
const _hoisted_16 = { class: "modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createCourt && _ctx.createCourt(...args)), ["prevent"])),
      class: "form-container"
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("h1", null, "Criar Nova Quadra", -1)),
      (_ctx.loadingCreate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[7] || (_cache[7] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Enviando dados...", -1)
          ])))
        : _createCommentVNode("", true),
      (!_ctx.loadingCreate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[8] || (_cache[8] = _createElementVNode("label", { for: "nome" }, "Nome da Quadra", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.nome) = $event)),
                type: "text",
                id: "nome",
                placeholder: "Digite o nome da quadra",
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('nome')))
              }, null, 544), [
                [_vModelText, _ctx.state.nome]
              ]),
              (_ctx.state.errors.nome)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.state.errors.nome), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "patrocinador" }, "Patrocinador", -1)),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.idPatrocinador) = $event)),
                id: "patrocinador",
                onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateField('idPatrocinador')))
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("option", {
                  value: "",
                  disabled: ""
                }, "Selecione um patrocinador", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patrocinadores, (patrocinador) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: patrocinador.id,
                    value: patrocinador.id
                  }, _toDisplayString(patrocinador.nome), 9, _hoisted_7))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.state.idPatrocinador]
              ]),
              (_ctx.state.errors.idPatrocinador)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.state.errors.idPatrocinador), 1))
                : _createCommentVNode("", true)
            ]),
            _cache[11] || (_cache[11] = _createElementVNode("button", {
              type: "submit",
              class: "button"
            }, "Criar Quadra", -1))
          ]))
        : _createCommentVNode("", true)
    ], 32),
    _createElementVNode("div", _hoisted_9, [
      _cache[16] || (_cache[16] = _createElementVNode("h2", null, "Quadras Cadastradas", -1)),
      (_ctx.loadingData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[13] || (_cache[13] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("table", null, [
              _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Nome"),
                  _createElementVNode("th", null, "Patrocinador"),
                  _createElementVNode("th", null, "Ações")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courts, (court) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: court.id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(court.nome), 1),
                    _createElementVNode("td", null, _toDisplayString(court.patrocinador.nome), 1),
                    _createElementVNode("td", null, [
                      (_ctx.loadingDelete && _ctx.deletingCourtId === court.id)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[14] || (_cache[14] = [
                            _createElementVNode("div", { class: "loader small" }, null, -1)
                          ])))
                        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "trash",
                              class: "icon-trash",
                              onClick: ($event: any) => (_ctx.confirmDeleteCourt(court.id))
                            }, null, 8, ["onClick"])
                          ]))
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]))
    ]),
    (_ctx.showConfirmation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[17] || (_cache[17] = _createElementVNode("h3", null, "Confirmar Exclusão", -1)),
            _cache[18] || (_cache[18] = _createElementVNode("p", null, "Deseja realmente excluir esta quadra?", -1)),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("button", {
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.cancelDeleteCourt && _ctx.cancelDeleteCourt(...args))),
                class: "btn-cancel"
              }, "Cancelar"),
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args))),
                class: "btn-confirm"
              }, "Confirmar")
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}