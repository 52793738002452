import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "reset-password-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "error"
}
const _hoisted_7 = {
  key: 1,
  class: "token-expired"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon),
    (!_ctx.tokenExpired)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetPassword && _ctx.resetPassword(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Redefinir Senha", -1)),
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [
                    _createElementVNode("div", { class: "loader" }, null, -1),
                    _createElementVNode("p", null, "Redefinindo senha...", -1)
                  ])))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", { for: "newPassword" }, "Nova Senha", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "password",
                      id: "newPassword",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.newPassword) = $event)),
                      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('newPassword'))),
                      placeholder: "Digite a nova senha"
                    }, null, 544), [
                      [_vModelText, _ctx.state.newPassword]
                    ]),
                    (_ctx.state.errors.newPassword)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.state.errors.newPassword), 1))
                      : _createCommentVNode("", true),
                    _cache[5] || (_cache[5] = _createElementVNode("button", { type: "submit" }, "Definir nova senha", -1))
                  ]))
            ])
          ], 32)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[8] || (_cache[8] = _createElementVNode("p", null, "O token expirou. Por favor, solicite uma nova redefinição de senha.", -1)),
          _createVNode(_component_router_link, {
            class: "back-to-login",
            to: "/login"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Voltar para o login")
            ])),
            _: 1
          })
        ]))
  ]))
}