import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "sponsors-page" }
const _hoisted_2 = { class: "overlay" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "sponsors" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "sponsor-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Conheça nossos Patrocinadores", -1)),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "loader" }, null, -1),
              _createElementVNode("p", null, "Carregando dados...", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sponsors, (sponsor) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: sponsor.id,
                    class: "sponsor"
                  }, [
                    _createElementVNode("img", {
                      src: sponsor.imagem,
                      alt: "imagem do patrocinador"
                    }, null, 8, _hoisted_6),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h3", null, _toDisplayString(sponsor.nome), 1),
                      _createElementVNode("p", null, _toDisplayString(sponsor.descricao), 1)
                    ])
                  ]))
                }), 128))
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "partnership-invite" }, [
                _createElementVNode("h2", null, "Quer ser um de nossos parceiros?"),
                _createElementVNode("p", null, " Envie uma mensagem para nós e vamos conversar sobre como podemos crescer juntos. Para mais informações, entre em contato por e-mail: "),
                _createElementVNode("p", { class: "email" }, "arenasunsetlavras@gmail.com")
              ], -1))
            ]))
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}