import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-box" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon),
    _createElementVNode("div", _hoisted_2, [
      _cache[11] || (_cache[11] = _createElementVNode("h1", null, "Login", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("label", { for: "email" }, "Email", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                id: "email",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.email]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("label", { for: "senha" }, "Senha", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "password",
                id: "senha",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.senha) = $event)),
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.senha]
              ])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("button", { type: "submit" }, "Entrar", -1))
          ], 32)),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _cache[8] || (_cache[8] = _createTextVNode(" Não tem uma conta? ")),
            _createVNode(_component_router_link, { to: "/register" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Faça seu cadastro")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
            _cache[10] || (_cache[10] = _createTextVNode(" Esqueceu a senha? ")),
            _createVNode(_component_router_link, { to: "/forgot-password" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Redefinir senha")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}