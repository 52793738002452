import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "expiring-plans-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "plan-title" }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "title" }, "Planos Próximos ao Vencimento", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando planos...", -1)
          ])))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.expiringPlans.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expiringPlans, (plan) => {
              return (_openBlock(), _createElementBlock("div", {
                key: plan.id,
                class: "plan-card"
              }, [
                _createElementVNode("h2", _hoisted_5, _toDisplayString(plan.clienteNome), 1),
                _createElementVNode("p", null, [
                  _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Data de Início:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.formatDate(plan.dataInicio)), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Data de Término:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.formatDate(plan.dataFim)), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Faltam:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.calculateDaysLeft(plan.dataFim)) + " dias", 1)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.expiringPlans.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[4] || (_cache[4] = [
            _createElementVNode("p", null, "Não há planos expirando nos próximos 5 dias.", -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}