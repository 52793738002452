import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = {
  key: 0,
  class: "warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_CalendarView = _resolveComponent("CalendarView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/planos"
    }),
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "ESCOLHA UM DIA", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CalendarView, {
        selectedDate: _ctx.selectedDate,
        "onUpdate:selectedDate": _ctx.handleDateSelection
      }, null, 8, ["selectedDate", "onUpdate:selectedDate"]),
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"legend\" data-v-fc823d6c><div class=\"legend-item\" data-v-fc823d6c><span class=\"dot red\" data-v-fc823d6c></span> <span data-v-fc823d6c>Hoje</span></div><div class=\"legend-item\" data-v-fc823d6c><span class=\"dot blue\" data-v-fc823d6c></span> <span data-v-fc823d6c>Selecionado pelo usuário</span></div></div><div class=\"paragrafo\" data-v-fc823d6c><p class=\"description\" data-v-fc823d6c> Para reservas avulsas, você só pode selecionar um dia por vez. Se deseja agendar em outros dias, finalize sua compra atual e volte a esta página para realizar outra reserva. </p></div>", 2)),
      _createElementVNode("button", {
        class: "next-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToSelectTime && _ctx.goToSelectTime(...args)))
      }, "Próximo"),
      (_ctx.showWarning)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Por favor, selecione uma data primeiro."))
        : _createCommentVNode("", true)
    ])
  ]))
}