import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "create-teacher-page" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  key: 0,
  class: "error"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  key: 0,
  class: "error"
}
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  key: 0,
  class: "error"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  key: 0,
  class: "error"
}
const _hoisted_12 = { class: "teachers-list" }
const _hoisted_13 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "acoes-cell" }
const _hoisted_16 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 0,
  class: "confirmation-modal"
}
const _hoisted_20 = { class: "modal-content" }
const _hoisted_21 = { class: "modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _createElementVNode("form", {
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createTeacher && _ctx.createTeacher(...args)), ["prevent"])),
      class: "form-container"
    }, [
      _cache[17] || (_cache[17] = _createElementVNode("h1", null, "Criar Novo Professor", -1)),
      (_ctx.loadingCreate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[11] || (_cache[11] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Enviando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[12] || (_cache[12] = _createElementVNode("label", { for: "nome" }, "Nome do Professor", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.nome) = $event)),
                type: "text",
                id: "nome",
                placeholder: "Digite o nome do professor",
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('nome'))),
                maxlength: "30"
              }, null, 544), [
                [_vModelText, _ctx.state.nome]
              ]),
              (_ctx.state.errors.nome)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.state.errors.nome), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[13] || (_cache[13] = _createElementVNode("label", { for: "especialidade" }, "Especialidade", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.especialidade) = $event)),
                type: "text",
                id: "especialidade",
                placeholder: "Digite a especialidade do professor",
                onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateField('especialidade'))),
                maxlength: "50"
              }, null, 544), [
                [_vModelText, _ctx.state.especialidade]
              ]),
              (_ctx.state.errors.especialidade)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.state.errors.especialidade), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[14] || (_cache[14] = _createElementVNode("label", { for: "telefone" }, "Telefone", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.telefoneMasked) = $event)),
                type: "tel",
                id: "telefone",
                placeholder: "(XX) XXXXX-XXXX",
                onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validateField('telefone'))),
                maxlength: "15"
              }, null, 544), [
                [_vModelText, _ctx.telefoneMasked]
              ]),
              (_ctx.state.errors.telefone)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.state.errors.telefone), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[15] || (_cache[15] = _createElementVNode("label", { for: "descricao" }, "Descrição", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.descricao) = $event)),
                id: "descricao",
                placeholder: "Digite uma descrição",
                rows: "4",
                onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateField('descricao'))),
                maxlength: "200"
              }, null, 544), [
                [_vModelText, _ctx.state.descricao]
              ]),
              (_ctx.state.errors.descricao)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.state.errors.descricao), 1))
                : _createCommentVNode("", true)
            ]),
            _cache[16] || (_cache[16] = _createElementVNode("button", {
              type: "submit",
              class: "button"
            }, "Criar Professor", -1))
          ]))
    ], 32),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", null, [
        _cache[21] || (_cache[21] = _createElementVNode("h2", null, "Professores Cadastrados", -1)),
        (_ctx.loadingData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[18] || (_cache[18] = [
              _createElementVNode("div", { class: "loader" }, null, -1),
              _createElementVNode("p", null, "Carregando dados...", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("table", null, [
                _cache[20] || (_cache[20] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "Nome"),
                    _createElementVNode("th", null, "Especialidade"),
                    _createElementVNode("th", null, "Telefone"),
                    _createElementVNode("th", null, "Descrição"),
                    _createElementVNode("th", { class: "acoes-header" }, "Ações")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teachers, (teacher) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: teacher.id
                    }, [
                      _createElementVNode("td", null, _toDisplayString(teacher.nome), 1),
                      _createElementVNode("td", null, _toDisplayString(teacher.especialidade), 1),
                      _createElementVNode("td", null, _toDisplayString(_ctx.formatPhone(teacher.telefone)), 1),
                      _createElementVNode("td", null, _toDisplayString(teacher.descricao), 1),
                      _createElementVNode("td", _hoisted_15, [
                        (_ctx.loadingDelete && _ctx.deletingTeacherId === teacher.id)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[19] || (_cache[19] = [
                              _createElementVNode("div", { class: "loader small" }, null, -1)
                            ])))
                          : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("button", {
                                onClick: ($event: any) => (_ctx.confirmDeleteTeacher(teacher.id)),
                                class: "btn-delete",
                                title: "Excluir Professor"
                              }, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: "trash",
                                  class: "icon-trash"
                                })
                              ], 8, _hoisted_18)
                            ]))
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]))
      ])
    ]),
    (_ctx.showConfirmation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _cache[22] || (_cache[22] = _createElementVNode("h3", null, "Confirmar Exclusão", -1)),
            _cache[23] || (_cache[23] = _createElementVNode("p", null, "Deseja realmente excluir este professor?", -1)),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("button", {
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.cancelDeleteTeacher && _ctx.cancelDeleteTeacher(...args))),
                class: "btn-cancel"
              }, "Cancelar"),
              _createElementVNode("button", {
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args))),
                class: "btn-confirm"
              }, "Confirmar")
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}