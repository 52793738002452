<template>
  <footer class="site-footer">
    <div class="container">
      <div class="contact-info">
        <a
          href="https://wa.me/5535997681112"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp
        </a>
        <a
          href="https://www.instagram.com/sunsetarenalavras/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        
        <a href="mailto:sunsetarenainfo@gmail.com">sunsetarenainfo@gmail.com</a>
      </div>
      <div class="right-info">
        <a href="/sobre#refund-policy" class="refund-policy">
          Política de Reembolso
        </a>
        <div class="copyright">
          ©2024 por Sunset Arena Lavras.
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer'
});
</script>

<style scoped>
.site-footer {
  background: linear-gradient(to top, #f01f84, #FF6A00);
  color: white;
  padding: 5px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.contact-info,
.right-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Estilo dos links em ambas as seções */
.contact-info a,
.right-info a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.contact-info a:last-child,
.right-info a:last-child {
  margin-bottom: 0;
}

.contact-info a:hover,
.right-info a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Alinha os itens da direita */
.right-info {
  align-items: flex-end;
}

.right-info .refund-policy {
  margin-bottom: 10px;
}

.right-info .copyright {
  font-size: 14px;
  text-align: right;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .right-info {
    align-items: center;
    margin-bottom: 10px;
  }

  .right-info {
    align-items: center;
  }
}
</style>
