import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "sunset-header" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "login"
}
const _hoisted_4 = {
  href: "/account",
  class: "login-link"
}
const _hoisted_5 = {
  key: 1,
  class: "login"
}
const _hoisted_6 = {
  href: "/login",
  class: "login-link"
}
const _hoisted_7 = { class: "menu-toggle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "logo" }, [
        _createElementVNode("h1", null, "Sunset Arena")
      ], -1)),
      _createElementVNode("nav", {
        class: _normalizeClass(['navigation', { open: _ctx.isMenuOpen }])
      }, _cache[0] || (_cache[0] = [
        _createStaticVNode("<div class=\"menu-item\" data-v-5b5292e3><a href=\"/\" data-v-5b5292e3>Página inicial</a></div><div class=\"menu-item\" data-v-5b5292e3><a href=\"/planos\" data-v-5b5292e3>Agendar</a></div><div class=\"menu-item\" data-v-5b5292e3><a href=\"/sobre\" data-v-5b5292e3>Sobre</a></div><div class=\"menu-item\" data-v-5b5292e3><a href=\"/parcerias\" data-v-5b5292e3>Parcerias</a></div><div class=\"menu-item\" data-v-5b5292e3><a href=\"/aulas\" data-v-5b5292e3>Agendamento de aulas</a></div><div class=\"menu-item\" data-v-5b5292e3><a href=\"/planosView\" data-v-5b5292e3>Planos e preços</a></div>", 6)
      ]), 2),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("a", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'user-circle'],
                class: "login-icon"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.user.nome), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'user-circle'],
                class: "login-icon"
              }),
              _cache[1] || (_cache[1] = _createElementVNode("span", null, "Fazer login", -1))
            ])
          ])),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'bars'],
          onClick: _ctx.toggleMenu
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}