import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "create-user-section" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "error"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 0,
  class: "error"
}
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  key: 0,
  class: "error"
}
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "error"
}
const _hoisted_11 = { class: "form-group senha-group" }
const _hoisted_12 = { class: "senha-wrapper" }
const _hoisted_13 = ["type"]
const _hoisted_14 = {
  key: 0,
  class: "error"
}
const _hoisted_15 = { class: "search-user-section" }
const _hoisted_16 = { class: "search-section" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_19 = {
  key: 1,
  class: "user-dropdown"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 2,
  class: "no-results"
}
const _hoisted_22 = {
  key: 0,
  class: "user-details"
}
const _hoisted_23 = { class: "header" }
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = {
  key: 0,
  class: "error"
}
const _hoisted_27 = { class: "form-group" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = {
  key: 0,
  class: "error"
}
const _hoisted_30 = { class: "form-group" }
const _hoisted_31 = ["disabled"]
const _hoisted_32 = {
  key: 0,
  class: "error"
}
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = ["disabled"]
const _hoisted_35 = {
  key: 0,
  class: "error"
}
const _hoisted_36 = {
  key: 0,
  class: "edit-actions"
}
const _hoisted_37 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "user-registration-page",
    onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "adm-dashboard"
    }),
    _createElementVNode("div", _hoisted_1, [
      _cache[33] || (_cache[33] = _createElementVNode("h3", null, "Criar Novo Usuário", -1)),
      (_ctx.loadingAction)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[26] || (_cache[26] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Enviando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createUser && _ctx.createUser(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _cache[27] || (_cache[27] = _createElementVNode("label", { for: "nome" }, "Nome", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "nome",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUser.nome) = $event)),
                placeholder: "Digite o nome",
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('nome', _ctx.newUser)))
              }, null, 544), [
                [_vModelText, _ctx.newUser.nome]
              ]),
              (_ctx.newUser.errors?.nome)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.newUser.errors?.nome), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[28] || (_cache[28] = _createElementVNode("label", { for: "email" }, "Email", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                id: "email",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUser.email) = $event)),
                placeholder: "Digite o email",
                onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateField('email', _ctx.newUser)))
              }, null, 544), [
                [_vModelText, _ctx.newUser.email]
              ]),
              (_ctx.newUser.errors?.email)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.newUser.errors?.email), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[29] || (_cache[29] = _createElementVNode("label", { for: "telefone" }, "Telefone", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "tel",
                id: "telefone",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newUser.telefone) = $event)),
                placeholder: "Digite o telefone",
                onInput: _cache[5] || (_cache[5] = ($event: any) => {_ctx.validateField('telefone', _ctx.newUser); _ctx.applyPhoneMask($event, _ctx.newUser)})
              }, null, 544), [
                [_vModelText, _ctx.newUser.telefone]
              ]),
              (_ctx.newUser.errors?.telefone)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.newUser.errors?.telefone), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[30] || (_cache[30] = _createElementVNode("label", { for: "cpf" }, "CPF", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "cpf",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newUser.cpf) = $event)),
                placeholder: "Digite o CPF",
                onInput: _cache[7] || (_cache[7] = ($event: any) => {_ctx.validateField('cpf', _ctx.newUser); _ctx.applyCpfMask($event, _ctx.newUser)})
              }, null, 544), [
                [_vModelText, _ctx.newUser.cpf]
              ]),
              (_ctx.newUser.errors?.cpf)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.newUser.errors?.cpf), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[31] || (_cache[31] = _createElementVNode("label", { for: "senha" }, "Senha", -1)),
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("input", {
                  type: _ctx.mostrarSenha ? 'text' : 'password',
                  id: "senha",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newUser.senha) = $event)),
                  placeholder: "Digite a senha",
                  onInput: _cache[9] || (_cache[9] = ($event: any) => (_ctx.validateField('senha', _ctx.newUser)))
                }, null, 40, _hoisted_13), [
                  [_vModelDynamic, _ctx.newUser.senha]
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "toggle-password",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.mostrarSenha = !_ctx.mostrarSenha))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: _ctx.mostrarSenha ? 'eye-slash' : 'eye'
                  }, null, 8, ["icon"])
                ])
              ]),
              (_ctx.newUser.errors?.senha)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.newUser.errors?.senha), 1))
                : _createCommentVNode("", true)
            ]),
            _cache[32] || (_cache[32] = _createElementVNode("button", {
              type: "submit",
              class: "button create-button"
            }, "Criar Usuário", -1))
          ], 32))
    ]),
    _createElementVNode("div", _hoisted_15, [
      _cache[41] || (_cache[41] = _createElementVNode("h3", null, "Buscar Usuário Existente", -1)),
      _createElementVNode("div", _hoisted_16, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.searchQuery) = $event)),
          onInput: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          onFocus: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
          placeholder: "Digite o nome ou email do usuário",
          class: "search-input",
          disabled: _ctx.isEditing
        }, null, 40, _hoisted_17), [
          [_vModelText, _ctx.searchQuery]
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[34] || (_cache[34] = [
              _createElementVNode("div", { class: "loader" }, null, -1),
              _createElementVNode("p", null, "Carregando dados...", -1)
            ])))
          : _createCommentVNode("", true),
        (!_ctx.loading && _ctx.showDropdown && _ctx.filteredUsers.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_19, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredUsers, (user) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: user.id,
                  onClick: _withModifiers(($event: any) => (_ctx.selectUser(user)), ["stop"])
                }, _toDisplayString(user.nome) + " - " + _toDisplayString(user.email), 9, _hoisted_20))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading && _ctx.searchQuery.trim().length > 0 && _ctx.filteredUsers.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_21, " Nenhum usuário encontrado. "))
          : _createCommentVNode("", true)
      ]),
      (_ctx.selectedUser)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _cache[35] || (_cache[35] = _createElementVNode("h4", null, "Dados do Usuário: ", -1)),
              (_ctx.isEditing)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.cancelEdit && _ctx.cancelEdit(...args))),
                    class: "cancel-x"
                  }, "✕"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("form", {
              onSubmit: _cache[24] || (_cache[24] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveUser && _ctx.saveUser(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_24, [
                _cache[36] || (_cache[36] = _createElementVNode("label", { for: "nome" }, "Nome", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "nome",
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectedUser.nome) = $event)),
                  disabled: !_ctx.isEditing
                }, null, 8, _hoisted_25), [
                  [_vModelText, _ctx.selectedUser.nome]
                ]),
                (_ctx.selectedUser.errors?.nome)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.selectedUser.errors?.nome), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _cache[37] || (_cache[37] = _createElementVNode("label", { for: "email" }, "Email", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "email",
                  id: "email",
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.selectedUser.email) = $event)),
                  disabled: !_ctx.isEditing
                }, null, 8, _hoisted_28), [
                  [_vModelText, _ctx.selectedUser.email]
                ]),
                (_ctx.selectedUser.errors?.email)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.selectedUser.errors?.email), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_30, [
                _cache[38] || (_cache[38] = _createElementVNode("label", { for: "telefone" }, "Telefone", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "tel",
                  id: "telefone",
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.selectedUser.telefone) = $event)),
                  onInput: _cache[19] || (_cache[19] = ($event: any) => (_ctx.applyPhoneMask($event, _ctx.selectedUser))),
                  disabled: !_ctx.isEditing
                }, null, 40, _hoisted_31), [
                  [_vModelText, _ctx.selectedUser.telefone]
                ]),
                (_ctx.selectedUser.errors?.telefone)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.selectedUser.errors?.telefone), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_33, [
                _cache[39] || (_cache[39] = _createElementVNode("label", { for: "cpf" }, "CPF", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "cpf",
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.selectedUser.cpf) = $event)),
                  onInput: _cache[21] || (_cache[21] = ($event: any) => (_ctx.applyCpfMask($event, _ctx.selectedUser))),
                  disabled: !_ctx.isEditing
                }, null, 40, _hoisted_34), [
                  [_vModelText, _ctx.selectedUser.cpf]
                ]),
                (_ctx.selectedUser.errors?.cpf)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_ctx.selectedUser.errors?.cpf), 1))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isEditing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[40] || (_cache[40] = [
                    _createElementVNode("button", {
                      type: "submit",
                      class: "button save-button"
                    }, "Salvar", -1)
                  ])))
                : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.editUser && _ctx.editUser(...args))),
                      class: "button edit-button"
                    }, "Editar"),
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.deleteUser && _ctx.deleteUser(...args))),
                      class: "button delete-button"
                    }, "Excluir")
                  ]))
            ], 32)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}