import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "select-day-page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "error-message"
}
const _hoisted_4 = {
  key: 2,
  class: "container"
}
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "planos"
    }),
    (!_ctx.error)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "Escolha o dia da semana"))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.error), 1),
          _createVNode(_component_router_link, {
            to: { name: 'Planos' },
            class: "back-button"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Voltar para Seleção de Planos")
            ])),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h2", null, "Plano " + _toDisplayString(_ctx.namePlan), 1),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDay) = $event)),
            class: "dropdown"
          }, [
            _cache[3] || (_cache[3] = _createElementVNode("option", {
              disabled: "",
              value: "-1"
            }, "Selecione um dia", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysOfWeek, (day, index) => {
              return (_openBlock(), _createElementBlock("option", {
                key: index,
                value: index
              }, _toDisplayString(day), 9, _hoisted_5))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.selectedDay]
          ]),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToSelectTime && _ctx.goToSelectTime(...args))),
            class: "next-button"
          }, "Próximo")
        ]))
  ]))
}