import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "employee-dashboard" }
const _hoisted_2 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/account"
    }),
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Painel de Controle de Funcionários", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/employee-horarios",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "button-title" }, "Ver Horários Ocupados", -1),
          _createElementVNode("span", { class: "button-desc" }, "Consulte os horários reservados e disponibilidade", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/employee-searchClients",
        class: "dashboard-button"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "button-title" }, "Buscar Usuário", -1),
          _createElementVNode("span", { class: "button-desc" }, "Pesquise e visualize informações dos usuários", -1)
        ])),
        _: 1
      })
    ])
  ]))
}