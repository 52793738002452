import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "forgot-password-container" }
const _hoisted_2 = { class: "forgot-password-box" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/login"
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Redefinir Senha", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Enviando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleForgotPassword && _ctx.handleForgotPassword(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("label", { for: "email" }, "Email", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                id: "email",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.email]
              ])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("button", { type: "submit" }, "Enviar Link de Redefinição", -1))
          ], 32))
    ])
  ]))
}