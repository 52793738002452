import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "success-page" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "refunded-container"
}
const _hoisted_5 = {
  key: 1,
  class: "error-container"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "details-card" }
const _hoisted_8 = { class: "badge-container" }
const _hoisted_9 = {
  key: 0,
  class: "clubinho-body"
}
const _hoisted_10 = { class: "plan-item" }
const _hoisted_11 = { class: "plan-item" }
const _hoisted_12 = { class: "plan-item" }
const _hoisted_13 = {
  key: 1,
  class: "plan-body"
}
const _hoisted_14 = { class: "plan-item" }
const _hoisted_15 = { class: "plan-item" }
const _hoisted_16 = { class: "plan-item" }
const _hoisted_17 = { class: "plan-item" }
const _hoisted_18 = { class: "plan-item" }
const _hoisted_19 = { class: "horarios-grid" }
const _hoisted_20 = {
  key: 2,
  class: "renewal-body"
}
const _hoisted_21 = { class: "plan-item" }
const _hoisted_22 = { class: "plan-item" }
const _hoisted_23 = { class: "plan-item" }
const _hoisted_24 = { class: "plan-item" }
const _hoisted_25 = { class: "plan-item" }
const _hoisted_26 = { class: "plan-item" }
const _hoisted_27 = { class: "horarios-grid" }
const _hoisted_28 = {
  key: 3,
  class: "renewal-body"
}
const _hoisted_29 = { class: "plan-item" }
const _hoisted_30 = { class: "plan-item" }
const _hoisted_31 = { class: "plan-item" }
const _hoisted_32 = { class: "plan-item" }
const _hoisted_33 = {
  key: 4,
  class: "ticket-body"
}
const _hoisted_34 = { class: "ticket-item" }
const _hoisted_35 = { class: "ticket-item" }
const _hoisted_36 = { class: "horarios-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "loading-animation" }, [
            _createElementVNode("h1", null, "Carregando detalhes...")
          ], -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.refunded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
                _createElementVNode("h1", null, "Pagamento Reembolsado", -1),
                _createElementVNode("p", null, " Infelizmente ocorreu um conflito ou problema na reserva, e seu pagamento foi reembolsado. Por favor, selecione outro horário ou tente novamente. ", -1)
              ])))
            : (_ctx.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Erro", -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.error), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[24] || (_cache[24] = _createElementVNode("div", { class: "details-header" }, [
                      _createElementVNode("h2", null, "SUNSET ARENA")
                    ], -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", {
                        class: _normalizeClass({
                'badge-plan': _ctx.sessionDetails?.type === 'plano',
                'badge-reservation': _ctx.sessionDetails?.type === 'reserva',
                'badge-renovation': _ctx.sessionDetails?.type === 'renovacao' || _ctx.sessionDetails?.type === 'renovacaoClubinho',
                'badge-clubinho': _ctx.sessionDetails?.type === 'clubinho',
              })
                      }, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.sessionDetails?.type === 'plano'
                    ? 'Plano'
                    : _ctx.sessionDetails?.type === 'renovacao' || _ctx.sessionDetails?.type === 'renovacaoClubinho'
                    ? 'Renovação'
                    : _ctx.sessionDetails?.type === 'clubinho'
                    ? 'Clubinho'
                    : 'Avulso'), 1)
                      ], 2)
                    ]),
                    (_ctx.sessionDetails?.type === 'clubinho')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("p", null, [
                              _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Tipo do Plano:", -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.plan), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("p", null, [
                              _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Data de Início:", -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.dataInicio), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("p", null, [
                              _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Data de Fim:", -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.dataFim), 1)
                            ])
                          ])
                        ]))
                      : (_ctx.sessionDetails?.type === 'plano')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("p", null, [
                                _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Tipo do Plano:", -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.plan), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("p", null, [
                                _cache[8] || (_cache[8] = _createElementVNode("strong", null, "Data de Início:", -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.startDate)), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("p", null, [
                                _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Data de Fim:", -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.endDate)), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("p", null, [
                                _cache[10] || (_cache[10] = _createElementVNode("strong", null, "Quadra:", -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.quadra), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _cache[11] || (_cache[11] = _createElementVNode("p", null, [
                                _createElementVNode("strong", null, "Horários:")
                              ], -1)),
                              _createElementVNode("div", _hoisted_19, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessionDetails?.horarios || [], (hour, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: index,
                                    class: "horario-item"
                                  }, _toDisplayString(hour), 1))
                                }), 128))
                              ])
                            ])
                          ]))
                        : (_ctx.sessionDetails?.type === 'renovacao')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("p", null, [
                                  _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Tipo do Plano:", -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.plan), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("p", null, [
                                  _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Data de Início da Reserva Original:", -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.dataInicio)), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("p", null, [
                                  _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Data de Fim Inicial:", -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.firstEndDate)), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("p", null, [
                                  _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Nova Data de Fim:", -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.endDate)), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_25, [
                                _createElementVNode("p", null, [
                                  _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Quadra:", -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.quadra), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_26, [
                                _cache[17] || (_cache[17] = _createElementVNode("p", null, [
                                  _createElementVNode("strong", null, "Horários:")
                                ], -1)),
                                _createElementVNode("div", _hoisted_27, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((JSON.parse(_ctx.sessionDetails.horarios) || []), (hour, index) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      key: index,
                                      class: "horario-item"
                                    }, _toDisplayString(hour), 1))
                                  }), 128))
                                ])
                              ])
                            ]))
                          : (_ctx.sessionDetails?.type === 'renovacaoClubinho')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                _createElementVNode("div", _hoisted_29, [
                                  _createElementVNode("p", null, [
                                    _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Tipo do Plano:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.sessionDetails?.plan), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_30, [
                                  _createElementVNode("p", null, [
                                    _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Data de Início da Reserva Original:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.dataInicio)), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_31, [
                                  _createElementVNode("p", null, [
                                    _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Data de Fim Inicial:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.firstEndDate)), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_32, [
                                  _createElementVNode("p", null, [
                                    _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Nova Data de Fim:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.endDate)), 1)
                                  ])
                                ])
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("p", null, [
                                    _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Data:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.sessionDetails?.dates?.[0])), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_35, [
                                  _cache[23] || (_cache[23] = _createElementVNode("p", null, [
                                    _createElementVNode("strong", null, "Quadras e Horários:")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_36, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessionDetails?.entries || [], (entry, index) => {
                                      return (_openBlock(), _createElementBlock("span", {
                                        key: index,
                                        class: "horario-item"
                                      }, _toDisplayString(entry.courtName) + " às " + _toDisplayString(entry.hour), 1))
                                    }), 128))
                                  ])
                                ])
                              ])),
                    _cache[25] || (_cache[25] = _createElementVNode("div", { class: "thank-you-message" }, [
                      _createElementVNode("p", null, "Obrigado por adquirir conosco! Aproveite sua experiência.")
                    ], -1))
                  ])
                ])),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args))),
            class: "home-button"
          }, "Voltar para a Home")
        ]))
  ]))
}