import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "court-schedules" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["min"]
const _hoisted_4 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_5 = {
  key: 1,
  class: "schedule-grid"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "employee-dashboard"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, "Horários para " + _toDisplayString(_ctx.formattedDate), 1),
      _withDirectives(_createElementVNode("input", {
        type: "date",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDateInput) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fetchSchedules && _ctx.fetchSchedules(...args))),
        min: _ctx.minDate
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.selectedDateInput]
      ]),
      (_ctx.loadingSchedules)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courts, (court) => {
              return (_openBlock(), _createElementBlock("div", {
                key: court.id,
                class: "court-section"
              }, [
                _createElementVNode("h4", null, _toDisplayString(court.nome), 1),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimes[court.id], (time) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: time.hour,
                      class: _normalizeClass({
                disabled: !time.available,
                past: _ctx.isPastSlot(time.hour),
                reserved: !time.available && !_ctx.isPastSlot(time.hour)
              })
                    }, [
                      _createTextVNode(_toDisplayString(time.hour) + " - ", 1),
                      (!time.available && !_ctx.isPastSlot(time.hour))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            _cache[3] || (_cache[3] = _createTextVNode(" Reservado por ")),
                            _createElementVNode("strong", null, _toDisplayString(time.reservadoPor || "Carregando..."), 1)
                          ]))
                        : (_ctx.isPastSlot(time.hour))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Indisponível "))
                          : (_openBlock(), _createElementBlock("span", _hoisted_8, " Disponível "))
                    ], 2))
                  }), 128))
                ])
              ]))
            }), 128))
          ]))
    ])
  ]))
}