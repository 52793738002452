import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plans-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "plans" }
const _hoisted_4 = { class: "plan avulso" }
const _hoisted_5 = { class: "icon" }
const _hoisted_6 = { class: "plan mensal" }
const _hoisted_7 = { class: "plan trimestral" }
const _hoisted_8 = { class: "plan clube" }
const _hoisted_9 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon),
    _createElementVNode("div", _hoisted_2, [
      _cache[14] || (_cache[14] = _createElementVNode("h1", null, "ESCOLHA SEU PLANO", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, {
              icon: "calendar-day",
              size: "3x"
            })
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Avulso", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("p", null, " Selecione um ou mais horários para reserva em qualquer data disponível nos próximos 7 dias.", -1)),
          _createVNode(_component_router_link, {
            to: { name: 'Calendar' },
            class: "button"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Agendar")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "icon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectPlan('mensal')))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "calendar-check",
              size: "3x"
            })
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Mensal", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("p", null, " Garanta suas reservas semanais por um mês, com desconto em relação à reserva avulsa. ", -1)),
          _createVNode(_component_router_link, {
            to: { name: 'SelectDayPage', query: { planId: 1 } },
            class: "button"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Agendar")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "icon",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectPlan('trimestral')))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "calendar-alt",
              size: "3x"
            })
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("h2", null, "Trimestral", -1)),
          _cache[10] || (_cache[10] = _createElementVNode("p", null, " Garanta suas reservas semanais por três semanas, com desconto em relação à reserva avulsa e ao plano mensal. ", -1)),
          _createVNode(_component_router_link, {
            to: { name: 'SelectDayPage', query: { planId: 2 } },
            class: "button"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Agendar")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_font_awesome_icon, {
              icon: "calendar-alt fa-solid fa-users",
              size: "3x"
            })
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("h2", null, "Clubinho", -1)),
          _cache[13] || (_cache[13] = _createElementVNode("p", null, " Acesso livre a quadras específicas de segunda a sexta. Temos diversas opções de preço, para diferentes casos. ", -1)),
          _createVNode(_component_router_link, {
            to: { name: 'Clubinho' },
            class: "button"
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Agendar")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}