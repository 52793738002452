<template>
  <div class="admin-dashboard">
    <HomeIcon :showArrow="true" to="/" />
    <h2>Painel de Controle Administrativo</h2>
    <div class="button-group">
      <router-link to="/adm-horarios" class="dashboard-button">
        <span class="button-title">Gerenciar Horários</span>
        <span class="button-desc">Crie, edite e visualize horários disponíveis</span>
      </router-link>

      <router-link to="/adm-quadras" class="dashboard-button">
        <span class="button-title">Gerenciar Quadras</span>
        <span class="button-desc">Adicione e remova quadras</span>
      </router-link>

      <router-link to="/adm-usuarios" class="dashboard-button">
        <span class="button-title">Criar e editar Usuários</span>
        <span class="button-desc">Adicione e remova usuários</span>
      </router-link>

      <router-link to="/adm-patrocinadores" class="dashboard-button">
        <span class="button-title">Gerenciar Patrocinadores</span>
        <span class="button-desc">Adicione e remova patrocinadores</span>
      </router-link>

      <router-link to="/adm-choose-plan" class="dashboard-button">
        <span class="button-title">Gerenciar Planos</span>
        <span class="button-desc">Crie planos mensais, trimensais e do clubinho</span>
      </router-link>

      <router-link to="/adm-professor" class="dashboard-button">
        <span class="button-title">Gerenciar Professores</span>
        <span class="button-desc">Cadastre e controle professores e aulas</span>
      </router-link>

      <router-link to="/adm-search-clients" class="dashboard-button">
        <span class="button-title">Gerenciar Usuários</span>
        <span class="button-desc">Pesquise e edite horários e planos relacionadas a usuários</span>
      </router-link>

      <router-link to="/adm-data" class="dashboard-button">
        <span class="button-title">Gerar relatórios</span>
        <span class="button-desc">Gere relatórios em Excel</span>
      </router-link>

      <router-link to="/adm-create-plans-livre" class="dashboard-button">
        <span class="button-title">Criar Planos (sem limite)</span>
        <span class="button-desc">Crie planos sem restições de datas</span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'AdminDashboard',
  components: {
    HomeIcon
  }
});
</script>

<style scoped>
.admin-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  min-height: 100vh;
  background: linear-gradient(135deg, #6EE7B7 0%, #3B82F6 100%);
  text-align: center;
}

h2 {
  font-size: 2.6rem;
  color: #ffffff;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.button-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  max-width: 900px;
  width: 100%;
  justify-content: center;
}

.dashboard-button {
  display: flex;
  flex-direction: column; /* Para título e descrição ficarem em coluna */
  align-items: center;
  justify-content: center;
  padding: 18px;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 12px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.dashboard-button:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.button-title {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.button-desc {
  font-size: 0.9rem;
  text-transform: none;
  letter-spacing: 0;
  color: #e0e0e0;
  text-align: center;
  max-width: 200px;
}

@media (max-width: 768px) {
  h2 {
    font-size: 2.2rem;
  }
  
  .button-group {
    grid-template-columns: 1fr;
  }
  
  .dashboard-button {
    font-size: 1.1rem;
    padding: 16px;
  }

  .button-title {
    font-size: 1.1rem;
  }

  .button-desc {
    font-size: 0.8rem;
  }
}
</style>
