import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "clubinho-payment-page" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = {
  key: 1,
  class: "content-container"
}
const _hoisted_4 = { class: "details" }
const _hoisted_5 = {
  key: 2,
  class: "modal-overlay"
}
const _hoisted_6 = { class: "modal-container" }
const _hoisted_7 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/clubinho"
    }),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "loader" }, null, -1),
          _createElementVNode("p", null, "Carregando dados...", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createElementVNode("h1", null, "Finalize sua Assinatura do Clubinho Aluno", -1)),
          _cache[9] || (_cache[9] = _createElementVNode("p", { class: "description" }, [
            _createTextVNode(" Você deve ser "),
            _createElementVNode("strong", null, "aluno"),
            _createTextVNode(" para adquirir este plano. Clique no botão abaixo para prosseguir com o pagamento. Nossa equipe verificará sua comprovação de aluno após o pagamento; se não for confirmada, a assinatura será cancelada. ")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _cache[7] || (_cache[7] = _createElementVNode("p", null, [
              _createElementVNode("span", null, "Plano:"),
              _createTextVNode(" Clubinho Aluno")
            ], -1)),
            _createElementVNode("p", null, [
              _cache[5] || (_cache[5] = _createElementVNode("span", null, "Início:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.startDate), 1)
            ]),
            _createElementVNode("p", null, [
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "Fim:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.endDate), 1)
            ])
          ]),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPaymentModal = true)),
            class: "button"
          }, " Ir para o Pagamento ")
        ])),
    (_ctx.showPaymentModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Atenção!", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("p", null, " Após concluir o pagamento, nossa equipe verificará seus dados em nossa base de alunos. Caso não seja confirmado, sua assinatura será cancelada. ", -1)),
            _cache[12] || (_cache[12] = _createElementVNode("p", null, " Você pode pagar via Stripe (cartão) ou via PIX. Para pagar via PIX, clique no botão abaixo para enviar uma mensagem para nosso WhatsApp. ", -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelPayment && _ctx.cancelPayment(...args))),
                class: "modal-button cancel"
              }, "Cancelar"),
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.confirmPayment && _ctx.confirmPayment(...args))),
                class: "modal-button confirm"
              }, "Continuar (Stripe)"),
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToWhatsapp && _ctx.goToWhatsapp(...args))),
                class: "modal-button whatsapp"
              }, "Pagar via PIX (WhatsApp)")
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}