import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-banner" }
const _hoisted_2 = { class: "event-content" }
const _hoisted_3 = { class: "overlay" }
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Swiper, {
      modules: [_ctx.Autoplay, _ctx.Pagination],
      loop: "",
      autoplay: { delay: 5000, disableOnInteraction: false },
      pagination: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SwiperSlide, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "slide event-slide",
              style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.eventImage + ')' })
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToTournament && _ctx.goToTournament(...args)))
                }, "Inscreva-se no Torneio")
              ])
            ], 4)
          ]),
          _: 1
        }),
        _createVNode(_component_SwiperSlide, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "slide home-slide",
              style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.homeImage + ')' })
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Seja Bem-Vindo!", -1)),
                  _cache[3] || (_cache[3] = _createElementVNode("p", null, " Conheça a Sunset Arena Lavras, muito mais que um complexo de quadras de esportes de areia! Venha nos visitar! ", -1)),
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToCalendar && _ctx.goToCalendar(...args)))
                  }, "Agendar")
                ])
              ])
            ], 4)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modules"])
  ]))
}