import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "search-section" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = {
  key: 1,
  class: "client-dropdown"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 2,
  class: "no-results"
}
const _hoisted_7 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_8 = {
  key: 1,
  class: "client-details"
}
const _hoisted_9 = { class: "client-section" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "plano-info" }
const _hoisted_12 = { class: "plano-tipo" }
const _hoisted_13 = { class: "plano-nome" }
const _hoisted_14 = {
  key: 0,
  class: "plano-details"
}
const _hoisted_15 = {
  key: 1,
  class: "empty-info"
}
const _hoisted_16 = { class: "client-section" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  key: 1,
  class: "empty-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "employee-clients-page",
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "employee-dashboard"
    }),
    _createElementVNode("div", _hoisted_1, [
      _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Buscar Usuários", -1)),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDropdown = true)),
          placeholder: "Digite o nome ou email do usuário",
          class: "search-input"
        }, null, 544), [
          [_vModelText, _ctx.searchQuery]
        ]),
        (_ctx.loadingClients)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "loader" }, null, -1),
              _createElementVNode("p", null, "Carregando dados...", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.showDropdown && !_ctx.loadingClients && _ctx.filteredClients.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredClients, (client) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: client.id,
                  onClick: _withModifiers(($event: any) => (_ctx.selectClient(client)), ["stop"])
                }, _toDisplayString(client.nome) + " - " + _toDisplayString(client.email), 9, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingClients && _ctx.searchQuery.trim().length > 0 && _ctx.filteredClients.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Nenhum cliente encontrado. "))
          : _createCommentVNode("", true)
      ]),
      (_ctx.loadingDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.selectedClient && !_ctx.loadingDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("h4", null, "Detalhes do Usuário: " + _toDisplayString(_ctx.selectedClient.nome), 1),
            _createElementVNode("p", null, [
              _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Email:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.selectedClient.email), 1)
            ]),
            _createElementVNode("p", null, [
              _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Telefone:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.selectedClient.telefone), 1)
            ]),
            _createElementVNode("p", null, [
              _cache[8] || (_cache[8] = _createElementVNode("strong", null, "CPF:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.selectedClient.cpf), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[12] || (_cache[12] = _createElementVNode("h5", null, "Planos e Clubinhos Ativos:", -1)),
              (_ctx.filteredPlanos && _ctx.filteredPlanos.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPlanos, (plano) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: plano.id,
                        class: "plano-item"
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("strong", null, _toDisplayString(plano.tipo) + " " + _toDisplayString(plano.id), 1)
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("strong", null, _toDisplayString(plano.nome), 1),
                            _createTextVNode(": " + _toDisplayString(_ctx.formatDate(plano.dataInicio)) + " - " + _toDisplayString(_ctx.formatDate(plano.dataFim)), 1)
                          ]),
                          (plano.tipo === 'Plano')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createElementVNode("div", null, [
                                  _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Quadra:", -1)),
                                  _createTextVNode(" " + _toDisplayString(plano.quadra?.nome || "N/A"), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _cache[10] || (_cache[10] = _createElementVNode("strong", null, "Dia da Semana:", -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.formatDayOfWeek(plano.indexDia)), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _cache[11] || (_cache[11] = _createElementVNode("strong", null, "Horários:", -1)),
                                  _createTextVNode(" " + _toDisplayString(plano.horarios), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_15, "Nenhum plano ou clubinho ativo encontrado."))
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[13] || (_cache[13] = _createElementVNode("h5", null, "Horários Reservados:", -1)),
              (_ctx.filteredHorarios.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredHorarios, (horario) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: horario.id
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(horario.data)) + " " + _toDisplayString(horario.horario) + " - " + _toDisplayString(horario.quadra.nome) + " ", 1),
                        (horario.idReserva !== null)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_18, " - faz parte do plano: " + _toDisplayString(horario.idReserva), 1))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_19, "Nenhum horário reservado encontrado."))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}