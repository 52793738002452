import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "clubinho-page" }
const _hoisted_2 = { class: "hero-section" }
const _hoisted_3 = { class: "hero-content" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = {
  class: "benefits",
  ref: "benefitsSection"
}
const _hoisted_6 = { class: "section-title" }
const _hoisted_7 = { class: "benefits-grid" }
const _hoisted_8 = { class: "benefit-card" }
const _hoisted_9 = { class: "icon" }
const _hoisted_10 = { class: "benefit-card" }
const _hoisted_11 = { class: "icon" }
const _hoisted_12 = { class: "benefit-card" }
const _hoisted_13 = { class: "icon" }
const _hoisted_14 = { class: "benefit-card" }
const _hoisted_15 = { class: "icon" }
const _hoisted_16 = { class: "plans" }
const _hoisted_17 = { class: "section-title sec2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "overlay" }, null, -1)),
      _createVNode(_component_HomeIcon, {
        to: '/planos',
        showArrow: true
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", {
          class: _normalizeClass(['title', { animate: _ctx.isAnimated }])
        }, "Descubra o Clubinho Sunset Arena", 2),
        _createElementVNode("p", {
          class: _normalizeClass(['subtitle', { animate: _ctx.isAnimated }])
        }, " O espaço ideal para Beach Tennis! Benefícios exclusivos e liberdade total para jogar como e quando quiser. ", 2),
        _createElementVNode("button", {
          class: _normalizeClass(["cta-button", { animate: _ctx.isAnimated }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToBenefits && _ctx.scrollToBenefits(...args)))
        }, " Junte-se Agora ", 2)
      ])
    ]),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, [
          _createVNode(_component_font_awesome_icon, {
            icon: "gift",
            class: "section-icon"
          }),
          _cache[2] || (_cache[2] = _createTextVNode(" Benefícios Exclusivos "))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_font_awesome_icon, { icon: "calendar-day" })
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Acesso Livre", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, "Jogue de Segunda a Sexta com liberdade total nos horários permitidos.", -1))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_font_awesome_icon, { icon: "tag" })
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Descontos", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("p", null, "Economize nas reservas avulsas com preços especiais para membros.", -1))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_font_awesome_icon, { icon: "umbrella-beach" })
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Day Use", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("p", null, "Curta finais de semana e feriados com acesso exclusivo às quadras.", -1))
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_font_awesome_icon, { icon: "trophy" })
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Eventos", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", null, "Participe de torneios, workshops e outras atividades incríveis.", -1))
          ])
        ])
      ], 512),
      _createElementVNode("section", _hoisted_16, [
        _createElementVNode("h2", _hoisted_17, [
          _createVNode(_component_font_awesome_icon, {
            icon: "money-check-alt",
            class: "section-icon si2"
          }),
          _cache[11] || (_cache[11] = _createTextVNode(" Escolha seu Pacote "))
        ]),
        _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"plans-grid\" data-v-45be5f44><div class=\"plan-card\" data-v-45be5f44><h3 data-v-45be5f44>Individual</h3><p class=\"price\" data-v-45be5f44>R$150/mês</p><a href=\"/individual\" class=\"cta-button-light\" data-v-45be5f44> Assinar </a></div><div class=\"plan-card\" data-v-45be5f44><h3 data-v-45be5f44>Estudante</h3><p class=\"price\" data-v-45be5f44>R$120/mês</p><p class=\"details\" data-v-45be5f44>Documento necessário</p><a href=\"/estudante\" class=\"cta-button-light\" data-v-45be5f44> Assinar </a></div><div class=\"plan-card\" data-v-45be5f44><h3 data-v-45be5f44>Família</h3><p class=\"price\" data-v-45be5f44>R$270/mês</p><p class=\"details\" data-v-45be5f44>Inclui casal e filhos até 12 anos</p><a href=\"/familia\" class=\"cta-button-light\" data-v-45be5f44> Assinar </a></div><div class=\"plan-card\" data-v-45be5f44><h3 data-v-45be5f44>Alunos Sunset</h3><p class=\"price\" data-v-45be5f44>R$100/mês</p><p class=\"details\" data-v-45be5f44>Comprovação necessária</p><a href=\"/aluno\" class=\"cta-button-light\" data-v-45be5f44>Assinar</a></div></div>", 1))
      ])
    ])
  ]))
}