<template>
  <div class="court-schedules">
    <HomeIcon :showArrow="true" to="employee-dashboard" />
    <div class="container">
      <h3>Horários para {{ formattedDate }}</h3>
      <input
        type="date"
        v-model="selectedDateInput"
        @change="fetchSchedules"
        :min="minDate"
      />

      <!-- Loader Condicional -->
      <div v-if="loadingSchedules" class="loader-container">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>

      <div v-else class="schedule-grid">
        <div v-for="court in courts" :key="court.id" class="court-section">
          <h4>{{ court.nome }}</h4>
          <ul>
            <li
              v-for="time in availableTimes[court.id]"
              :key="time.hour"
              :class="{
                disabled: !time.available,
                past: isPastSlot(time.hour),
                reserved: !time.available && !isPastSlot(time.hour)
              }"
            >
              {{ time.hour }} -
              <span v-if="!time.available && !isPastSlot(time.hour)">
                Reservado por <strong>{{ time.reservadoPor || "Carregando..." }}</strong>
              </span>
              <span v-else-if="isPastSlot(time.hour)">
                Indisponível
              </span>
              <span v-else>
                Disponível
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import HomeIcon from "../../components/HomeIcon.vue";

interface AvailableTime {
  hour: string;
  available: boolean;
  reservadoPor?: string | null;
}

interface Court {
  id: number;
  nome: string;
}

export default defineComponent({
  name: "EmployeeHorarios",
  components: { HomeIcon },
  setup() {
    const selectedDate = ref<Dayjs>(dayjs());
    const selectedDateInput = ref<string>(selectedDate.value.format("YYYY-MM-DD"));
    const minDate = ref<string>(dayjs().format("YYYY-MM-DD"));
    const courts = ref<Court[]>([]);
    const availableTimes = ref<{ [courtId: number]: AvailableTime[] }>({});
    const loadingSchedules = ref(false);

    const fetchCourts = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data.sort((a: Court, b: Court) => {
          const numberA = parseInt(a.nome.match(/\d+/)?.[0] || "0");
          const numberB = parseInt(b.nome.match(/\d+/)?.[0] || "0");
          return numberA - numberB;
        });
      } catch (error) {
        console.error("Erro ao buscar quadras:", error);
      }
    };

    const fetchOccupiedTimes = async (date: string, courtId: number) => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${date}/${courtId}`
        );

        return response.data.map((reservation: any) => ({
          horario: reservation.horario,
          nome: reservation.usuario.nome, // Captura o nome do usuário
        }));
      } catch (error) {
        console.error("Erro ao buscar horários ocupados:", error);
        return [];
      }
    };

    const generateAvailableTimes = async () => {
      const times: AvailableTime[] = [];
      const now = dayjs();

      for (let hour = 7; hour <= 21; hour++) {
        const time = selectedDate.value.clone().hour(hour).minute(0).second(0);
        const isAvailable =
          selectedDate.value.isAfter(now, "day") || time.isAfter(now);

        times.push({
          hour: time.format("HH:mm"),
          available: isAvailable,
        });
      }

      for (const court of courts.value) {
        const occupiedTimes = await fetchOccupiedTimes(
          selectedDate.value.format("YYYY-MM-DD"),
          court.id
        );

        const courtTimes = times.map((time) => {
          const reservation = occupiedTimes.find((res: any) => res.horario === time.hour);
          return {
            ...time,
            available: time.available && !reservation,
            reservadoPor: reservation ? reservation.nome : null, // Adiciona o nome do usuário
          };
        });

        availableTimes.value[court.id] = courtTimes;
      }

      loadingSchedules.value = false;
    };

    const fetchSchedules = async () => {
      loadingSchedules.value = true;
      if (!selectedDateInput.value) {
        selectedDateInput.value = dayjs().format("YYYY-MM-DD");
      }
      selectedDate.value = dayjs(selectedDateInput.value);
      await fetchCourts();
      await generateAvailableTimes();
    };

    const isPastSlot = (hour: string) => {
      const time = dayjs(
        `${selectedDate.value.format("YYYY-MM-DD")} ${hour}`,
        "YYYY-MM-DD HH:mm"
      );
      return time.isBefore(dayjs());
    };

    const formattedDate = computed(() =>
      dayjs(selectedDate.value).format("DD/MM/YYYY")
    );

    watch(selectedDateInput, (newValue) => {
      if (!newValue) {
        selectedDateInput.value = dayjs().format("YYYY-MM-DD");
      }
    });

    onMounted(() => {
      fetchSchedules();
    });

    return {
      selectedDateInput,
      courts,
      availableTimes,
      loadingSchedules,
      formattedDate,
      minDate,
      fetchSchedules,
      isPastSlot,
    };
  },
});
</script>

<style scoped>
.court-schedules {
  padding: 20px;
  background: linear-gradient(135deg, #34D399 0%, #059669 100%);
  min-height: 100vh;
}

.container {
  padding: 30px;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: white;
}

input[type="date"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #333;
  cursor: pointer;
}

.schedule-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.court-section {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.court-section h4 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: #444;
}

.court-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.court-section ul li {
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: #f8f9fa;
  transition: background 0.3s, color 0.3s;
}

.court-section ul li.disabled {
  background-color: #e2e3e5;
  color: #777;
  cursor: not-allowed;
}

.court-section ul li.available {
  background-color: #d4edda;
  color: #155724;
}

.court-section ul li.reserved {
  background-color: #f8d7da;
  color: #721c24;
}

.court-section ul li:hover:not(.disabled):not(.reserved) {
  background-color: #c3e6cb;
  color: #155724;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #10B981;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.loader-container p {
  margin: 0;
  font-size: 1.5rem;
  color: #ffffff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1034px) {
  .schedule-grid {
    grid-template-columns: repeat(3, minmax(220px, 1fr));
  }
}

@media (max-width: 768px) {
  .schedule-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media (max-width: 576px) {
  .court-section ul li {
    font-size: 0.9rem;
    padding: 10px;
  }

  input[type="date"] {
    font-size: 0.9rem;
    padding: 8px;
  }
}
</style>
