import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cancel-page" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "title" }, "Pagamento Cancelado", -1)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1),
      _createVNode(_component_router_link, {
        to: "/",
        class: "home-link"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Voltar à página inicial")
        ])),
        _: 1
      })
    ])
  ]))
}