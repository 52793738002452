<template>
  <div class="select-plan-page" @click="handleOutsideClick">
    <HomeIcon :showArrow="true" to="adm-dashboard" />
    <h1>Escolha Seu Plano</h1>

    <div class="container">
      <!-- Usuário -->
      <div class="user-search-wrapper">
        <label for="user-search">Usuário:</label>
        <div class="input-with-button">
          <input
            v-model="searchQuery"
            @input="onInput"
            @focus="showDropdown = true"
            :disabled="selectedUser !== null"
            placeholder="Digite o nome ou email do usuário"
            class="search-input"
          />
          <button v-if="selectedUser" @click="deselectUser" class="alterar-cliente-button">
            Alterar Cliente
          </button>
        </div>

        <div v-if="loadingUsers" class="loader-container">
          <div class="loader"></div>
          <p>Carregando usuários...</p>
        </div>

        <ul v-if="showDropdown && !loadingUsers && filteredUsers.length > 0" class="user-dropdown">
          <li v-for="user in filteredUsers" :key="user.id" @click.stop="selectUser(user)">
            {{ user.nome }} - {{ user.email }}
          </li>
        </ul>
      </div>

      <!-- Plano -->
      <div class="plan-selection">
        <label for="plan">Plano:</label>
        <select v-model="selectedPlan" id="plan">
          <option v-for="plan in plans" :key="plan.id" :value="plan.id">
            {{ plan.name }}
          </option>
        </select>
      </div>

      <!-- Quadra (somente para planos Mensal e Trimestral) -->
      <div v-if="requiresCourt" class="court-selection">
        <label for="court">Quadra:</label>
        <select v-model="selectedCourt" id="court">
          <option v-for="court in courts" :key="court.id" :value="court.id">
            {{ court.nome }}
          </option>
        </select>
      </div>

      <!-- Data de Início -->
      <div class="date-selection">
        <label for="date">Data de Início:</label>
        <input type="date" v-model="selectedDate" id="date" />
      </div>

      <!-- Horários (somente para planos Mensal e Trimestral) -->
      <div v-if="requiresTime" class="time-selection">
        <label>Horários:</label>

        <div v-for="(time, index) in selectedTimes" :key="index" class="time-dropdown">
          <select v-model="selectedTimes[index]" class="time-select">
            <option v-for="timeOption in availableTimes" :key="timeOption" :value="timeOption">
              {{ timeOption }}
            </option>
          </select>
          <button v-if="selectedTimes.length > 1" @click="removeTime(index)" class="remove-time-button">
            ❌
          </button>
        </div>

        <button @click="addTime" class="add-time-button">Adicionar Horário</button>
      </div>

      <button @click="submitReservation" class="plan-button">Confirmar Reserva</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import HomeIcon from "../../components/HomeIcon.vue";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default defineComponent({
  name: "AdmCreatePlansLivre",
  components: { HomeIcon },
  setup() {
    const router = useRouter();

    // Usuários
    const searchQuery = ref<string>("");
    const users = ref<any[]>([]);
    const selectedUser = ref<any | null>(null);
    const loadingUsers = ref(false);
    const showDropdown = ref(false);

    // Planos
    const selectedPlan = ref<number | null>(null);
    const selectedDate = ref<string>("");
    const selectedCourt = ref<number | null>(null);
    const selectedTimes = ref<string[]>([""]); // Agora pode armazenar múltiplos horários

    const plans = ref([
      { id: 1, name: "Plano Mensal" },
      { id: 2, name: "Plano Trimestral" },
      { id: 3, name: "Clubinho Família" },
      { id: 4, name: "Clubinho Individual" },
      { id: 5, name: "Clubinho Estudante" },
      { id: 6, name: "Clubinho Aluno" },
    ]);

    // Quadras
    const courts = ref<any[]>([]);
    const loadingCourts = ref(false);

    const fetchCourts = async () => {
      loadingCourts.value = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar quadras:", error);
      } finally {
        loadingCourts.value = false;
      }
    };

    onMounted(fetchCourts);

    const requiresTime = computed(() => selectedPlan.value === 1 || selectedPlan.value === 2);
    const requiresCourt = computed(() => selectedPlan.value === 1 || selectedPlan.value === 2);

    const submitReservation = async () => {
  if (!selectedPlan.value || !selectedDate.value || !selectedUser.value || (requiresTime.value && selectedTimes.value.length === 0) || (requiresCourt.value && !selectedCourt.value)) {
    alert("Preencha todas as opções corretamente.");
    return;
  }

  let dataFim = dayjs(selectedDate.value);
  dataFim = selectedPlan.value === 2 ? dataFim.add(3, "month") : dataFim.add(1, "month");

  // Calcula o indexDia (0 = Domingo, 6 = Sábado) usando Day.js UTC
  const indexDia = dayjs(selectedDate.value).utc().day(); 

  const payload: Record<string, any> = {
    planId: selectedPlan.value,
    dataInicio: selectedDate.value,
    dataFim: dataFim.format("YYYY-MM-DD"),
    idUsuario: selectedUser.value.id,
    startTimes: selectedTimes.value,
    indexDia, // Agora corretamente calculado
  };

  if (requiresCourt.value) payload["idQuadra"] = selectedCourt.value;

  try {
    await axios.post(`${process.env.VUE_APP_BACKEND_URL}/${selectedPlan.value > 2 ? "clubinhos" : "reservas/horarios"}`, payload);
    alert("Reserva criada com sucesso!");
  } catch (error) {
    console.error("Erro ao criar reserva:", error);
    alert("Erro ao criar reserva.");
  }
};

    // Adicionar mais horários
    const addTime = () => {
      if (selectedTimes.value.length < 5) {
        selectedTimes.value.push("");
      } else {
        alert("Máximo de 5 horários por reserva.");
      }
    };

    // Remover horário
    const removeTime = (index: number) => {
      selectedTimes.value.splice(index, 1);
    };

    // Fecha dropdown de usuários ao clicar fora
const handleOutsideClick = (event: Event) => {
  const target = event.target as HTMLElement;
  if (!target.closest(".user-search-wrapper")) {
    showDropdown.value = false;
  }
};

// Função debounce para busca de usuários
const debounceTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const onInput = () => {
  if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
  debounceTimeout.value = setTimeout(async () => {
    if (searchQuery.value.trim() === "") {
      users.value = [];
      return;
    }
    await searchUsers();
  }, 300);
};

// Filtra usuários com base na pesquisa
const filteredUsers = computed(() => {
  const query = searchQuery.value.toLowerCase().trim();
  return users.value.filter(user =>
    user.nome.toLowerCase().includes(query) || user.email.toLowerCase().includes(query)
  );
});

// Buscar usuários
const searchUsers = async () => {
  loadingUsers.value = true;
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/searchClients?query=${searchQuery.value}`);
    users.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar usuários:", error);
  } finally {
    loadingUsers.value = false;
  }
};

// Selecionar usuário
const selectUser = (user: any) => {
  selectedUser.value = user;
  searchQuery.value = `${user.nome} - ${user.email}`;
  showDropdown.value = false;
};

// Remover usuário selecionado
const deselectUser = () => {
  selectedUser.value = null;
  searchQuery.value = "";
};


    return {
      handleOutsideClick, searchQuery, onInput, showDropdown, selectedUser, deselectUser, loadingUsers, filteredUsers, selectUser, searchUsers,
      selectedPlan,
      selectedDate,
      selectedCourt,
      selectedTimes,
      plans,
      courts,
      requiresTime,
      requiresCourt,
      submitReservation,
      addTime,
      removeTime,
      availableTimes: ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"],
    };
  },
});
</script>

<style scoped>
.select-plan-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6EE7B7, #3B82F6);
  color: white;
  padding: 60px 20px;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 40px;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  text-align: center;
  text-transform: uppercase;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.container {
  background: white;
  color: #333;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 550px;
  position: relative;
  text-align: left;
}

.input-with-button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 14px;
  border: 2px solid #ddd;
  border-radius: 12px;
  font-size: 1.2rem;
  margin-right: 10px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: white;
}

.search-input:focus {
  border-color: #3B82F6;
}

.alterar-cliente-button {
  background-color: #34D399;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.alterar-cliente-button:hover {
  background-color: #10B981;
}

.user-dropdown {
  margin-top: 8px;
  list-style: none;
  padding: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-dropdown li {
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  text-align: left;
  transition: background-color 0.3s ease;
}

.user-dropdown li:hover {
  background-color: #f0faff;
}

.plan-selection,
.date-selection,
.time-selection {
  margin-top: 30px;
}

/* Resetando fundo dos inputs e selects */
select, 
input[type="date"], 
input[type="text"], 
input[type="email"] {
  background-color: white !important;
  color: #333;
  border: 1px solid #ccc;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  width: 100%;
}

/* Aparência do select */
select {
  appearance: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 18px;
  padding-right: 40px;
}

/* Garantir que os campos desativados fiquem visíveis */
select:disabled, 
input:disabled {
  background-color: #f9f9f9 !important;
  color: #999;
  cursor: not-allowed;
}

/* Tirar cinza do input de data */
input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

/* Borda azul ao focar */
select:focus, 
input[type="date"]:focus, 
input[type="text"]:focus {
  border-color: #3B82F6;
  outline: none;
}

/* Estilizando botão */
.plan-button {
  background-color: #3B82F6;
  color: white;
  padding: 15px 25px;
  border: none;
  border-radius: 12px;
  margin-top: 30px;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.plan-button:hover {
  background-color: #2563eb;
  transform: scale(1.05);
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #2563eb;
  animation: spin 1s linear infinite;
}

.loader-container p {
  font-size: 1.2rem;
  color: #000;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-results {
  color: #ff4d4d;
  font-size: 1rem;
  margin-top: 8px;
  text-align: center;
  font-weight: 500;
}

/* Responsividade */
@media (max-width: 768px) {
  h1 {
    font-size: 2.2rem;
  }

  .container {
    padding: 25px;
    max-width: 90%;
  }

  .plan-button {
    font-size: 1.2rem;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .search-input {
    font-size: 1.1rem;
  }

  .plan-button {
    font-size: 1.1rem;
    padding: 10px 18px;
  }
}

</style>
