import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/quadras3.jpeg'


const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "CONHEÇA NOSSAS QUADRAS", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "Localizado no centro de Lavras, Minas Gerais, a Sunset Arena conta com 5 quadras de areia para esportes de praia. Agende uma aula ou garanta seu aluguel online e prepare-se para a diversão!", -1)),
      _createVNode(_component_router_link, { to: "/sobre" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("button", null, "CONHECER", -1)
        ])),
        _: 1
      })
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "image" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Sunset Arena Lavras"
      })
    ], -1))
  ]))
}