import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "select-time-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_4 = {
  key: 1,
  class: "errorMain"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "modal-overlay"
}
const _hoisted_7 = { class: "modal-container" }
const _hoisted_8 = { class: "button-group" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "main-content" }
const _hoisted_11 = {
  key: 0,
  class: "legend"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "times" }
const _hoisted_14 = ["onClick", "disabled"]
const _hoisted_15 = { class: "total-price" }
const _hoisted_16 = {
  key: 2,
  class: "discount-message"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 3,
  class: "warning"
}
const _hoisted_19 = {
  key: 4,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeIcon, {
      showArrow: true,
      to: "/calendar"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "loader" }, null, -1),
            _createElementVNode("p", null, "Carregando dados...", -1)
          ])))
        : (_ctx.errorMain)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Erro ao validar o link. Ele pode estar expirado ou inválido. "))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.showPaymentWarning)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Atenção!", -1)),
                      _cache[7] || (_cache[7] = _createElementVNode("p", null, " Você tem 5 minutos para concluir o pagamento. Após esse tempo, sua sessão será expirada e os horários selecionados ficarão indisponíveis para pagamento. Após concluir o pagamento, espere a página de confirmação ser carregada para ter seu horário confirmado. ", -1)),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("button", {
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelPayment && _ctx.cancelPayment(...args))),
                          class: "modal-button cancel"
                        }, "Cancelar"),
                        _createElementVNode("button", {
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmPayment && _ctx.confirmPayment(...args))),
                          class: "modal-button confirm"
                        }, "Continuar")
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass({ 'blurred': _ctx.showPaymentWarning })
              }, [
                _createElementVNode("h1", _hoisted_9, "Selecione seus horários para " + _toDisplayString(_ctx.formattedDate), 1),
                _createElementVNode("div", _hoisted_10, [
                  _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Horários disponíveis:", -1)),
                  (_ctx.dayIndex !== 0 && _ctx.dayIndex !== 6)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[8] || (_cache[8] = [
                        _createElementVNode("div", { class: "legend-item" }, [
                          _createElementVNode("span", { class: "dot fifty-reais" }),
                          _createTextVNode(" R$50,00 (antes de 17:00 nas quadras 3, 4 e 5) ")
                        ], -1),
                        _createElementVNode("div", { class: "legend-item" }, [
                          _createElementVNode("span", { class: "dot eighty-reais" }),
                          _createTextVNode(" R$80,00 (a partir de 17:00, e antes disso nas quadras 1 e 2) ")
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[9] || (_cache[9] = [
                        _createElementVNode("div", { class: "legend-item" }, [
                          _createElementVNode("span", { class: "dot eighty-reais" }),
                          _createTextVNode(" R$80,00 (todos os horários) ")
                        ], -1)
                      ]))),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedCourts, (court) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: court.id,
                      class: "court"
                    }, [
                      _createElementVNode("h3", null, _toDisplayString(court.nome) + " - " + _toDisplayString(court.patrocinador.nome), 1),
                      _createElementVNode("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimes[court.id], (time) => {
                          return (_openBlock(), _createElementBlock("button", {
                            key: time.hour,
                            class: _normalizeClass({
                    disabled: !time.available,
                    selected: _ctx.isSelected(court.id, time.hour),
                    'before-five': !_ctx.isWeekend && time.hour < '17:00' && [3,4,5].includes(_ctx.extractCourtNumber(court.nome))
                  }),
                            onClick: ($event: any) => (_ctx.selectTime(court.id, time.hour)),
                            disabled: !time.available
                          }, _toDisplayString(time.hour), 11, _hoisted_14))
                        }), 128))
                      ])
                    ]))
                  }), 128)),
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "description" }, " É possível selecionar mais de um horário de uma vez, em diferentes quadras. ", -1)),
                  _createElementVNode("div", _hoisted_15, " Valor total: R$ " + _toDisplayString(_ctx.totalPrice.toFixed(2)), 1),
                  (_ctx.discountApplied)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("p", null, "Desconto aplicado por ter plano: " + _toDisplayString(_ctx.activePlanName), 1),
                        (_ctx.dayIndex !== 0 && _ctx.dayIndex !== 6)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_17, "(somente aos horários de 17:00 às 21:00, e antes disso nas quadras 1 e 2)"))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: _normalizeClass(["next-button", { clicked: _ctx.nextButtonClicked }]),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleNextButtonClick())),
                    onMousedown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.nextButtonClicked = true)),
                    onMouseup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.nextButtonClicked = false))
                  }, " Próximo ", 34),
                  (_ctx.showWarning)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_18, " Por favor, selecione um horário antes de prosseguir. "))
                    : _createCommentVNode("", true),
                  (_ctx.errorMessage)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.errorMessage), 1))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ]))
    ])
  ]))
}