<template>
  <div class="court-schedules" @click="handleOutsideClick">
    <HomeIcon :showArrow="true" to="adm-dashboard" />
    <div class="container">
      <h3>Horários para {{ formattedDate }}</h3>
      <input
        type="date"
        v-model="selectedDateInput"
        @change="fetchSchedules"
        :max="maxDate"
      />

      <!-- Loader Condicional -->
      <div v-if="loadingSchedules" class="loader-container">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>

      <div v-else>
        <div class="schedule-grid">
          <div v-for="court in courts" :key="court.id" class="court-section">
            <h4>{{ court.nome }}</h4>
            <ul>
              <li
                  v-for="(time) in availableTimes[court.id]"
                  :key="time.hour"
                  :class="{
                    stripe: time.pagamentoPorStripe,
                    available: time.available,
                    reserved: time.reservadoPor,
                    unavailable: !time.available && !time.reservadoPor,
                    selected: isSelected(court.id, time.hour)
                  }"
                  @click="time.available ? selectHour(court.id, court.nome, time.hour, time.available) : null"
                >
                  <div class="time-info">
                    {{ time.hour }}
                  </div>
                  <div class="reservation-info" v-if="!time.available && time.reservadoPor">
                    <strong>{{ time.reservadoPor || 'Indefinido' }}</strong>
                    <font-awesome-icon v-if="!time.isPast" icon="trash" :class="{
                    iconTrash: !time.pagamentoPorStripe,
                    iconTrashStripe: time.pagamentoPorStripe
                  }"  @click.stop="confirmDeleteHour(time.id, time.hour)" />
                  </div>
                  <span v-else-if="!time.available && !time.reservadoPor">Indisponível</span>
                  <span v-else>Disponível</span>
                </li>
            </ul>
          </div>
        </div>

        <div v-if="blockedTimes.length > 0" class="blocked-times-section">
  <h4>Horários Bloqueados</h4>
  <ul>
    <li v-for="blocked in blockedTimes" :key="`${blocked.court}-${blocked.hour}`">
      Quadra: {{ blocked.court }} - Horário: {{ blocked.hour }} - Usuário: {{ blocked.user }}
    </li>
  </ul>
</div>
<div v-else class="no-blocked-times">
  <p>Nenhuma sessão em andamento.</p>
</div>

        <div v-if="showConfirmation" class="modal-overlay">
          <div class="modal-container">
            <h2>Confirmar Exclusão</h2>
            <p>Deseja realmente excluir este horário?</p>
            <div class="button-group">
              <button @click="cancelDeleteHour" class="modal-button cancel">Cancelar</button>
              <button @click="confirmDelete" class="modal-button confirm">Continuar</button>
            </div>
          </div>
        </div>

        <!-- Formulário de Inserção -->
        <div class="reservation-form-container">
          <h3>Adicionar Reserva</h3>
          <div class="selected-hours-container">
            <h4>Quadras e Horários Selecionados</h4>
            <p v-if="selectedHours.length === 0" class="no-selected-hours">Nenhum horário selecionado</p>
            <ul class="selected-hours">
              <li v-for="(hour, index) in selectedHours" :key="index">
                {{ hour.courtName }} - {{ hour.hour }}
                <span class="remove" @click="removeHour(index)">
                  <font-awesome-icon icon="trash" />
                </span>
              </li>
            </ul>
          </div>

          <div class="form-group">
            <label for="client">Cliente</label>
            <div class="input-with-button">
              <input
                v-model="searchQuery"
                @input="onInput"
                :disabled="selectedClient !== null"
                 @focus="showDropdown = true"
                placeholder="Digite o nome ou email do cliente"
                class="form-control"
              />

              <button v-if="selectedClient" @click="clearClient" class="btn-change-client">
                Alterar Cliente
              </button>
            </div>

            <!-- Loader enquanto carrega os clientes -->
            <div v-if="loadingClients" class="loader-container-clients">
              <div class="loader-clients"></div>
              <p>Carregando dados...</p>
            </div>

            <!-- Dropdown de Clientes -->
            <ul v-if="showDropdown && !loadingClients && filteredClients.length > 0" class="client-dropdown">
              <li v-for="client in filteredClients" :key="client.id" @click="selectClient(client)">
                {{ client.nome }} - {{ client.email }}
              </li>
            </ul>
            <p
              v-if="!loadingClients && searchQuery.trim().length > 0 && filteredClients.length === 0"
              class="no-results"
            >
              Nenhum cliente encontrado.
            </p>
          </div>

          <button @click="saveReservation" class="btn-save" :disabled="!selectedClient || selectedHours.length === 0">
            Salvar Reserva
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import HomeIcon from "../../components/HomeIcon.vue";

interface AvailableTime {
  id: number;
  hour: string;
  available: boolean;
  reservadoPor?: string | null;
  isPast: boolean,
  pagamentoPorStripe: boolean | null,
}

interface Court {
  id: number;
  nome: string;
}

interface SelectedHour {
  courtId: number;
  courtName: string;
  hour: string;
}

export default defineComponent({
  name: "AdmHorarios",
  components: { HomeIcon },
  setup() {
    const selectedDate = ref<Dayjs>(dayjs());
    const selectedDateInput = ref<string>(selectedDate.value.format("YYYY-MM-DD"));
    const maxDate = ref<string>(dayjs().add(7, "day").format("YYYY-MM-DD"));
    const courts = ref<Court[]>([]);
    const availableTimes = ref<{ [courtId: number]: AvailableTime[] }>({});
    const loadingSchedules = ref(false);
    const selectedHours = ref<SelectedHour[]>([]);
    const searchQuery = ref<string>("");
    const clients = ref<any[]>([]);
    const filteredClients = ref<any[]>([]);
    const selectedClient = ref<any>(null);
    const loadingClients = ref(false);
    const showDropdown = ref(false);

    // Inicializações das variáveis que estavam causando erro
    const deletingHour = ref<number | null>(null); 
    const showConfirmation = ref(false);
    const blockedTimes = ref<{ court: string; hour: string; user: string }[]>([]);

    const ensureDateIsSet = () => {
  if (!selectedDateInput.value) {
    selectedDateInput.value = dayjs().format("YYYY-MM-DD");
  }
};


    const fetchBlockedTimes = async () => {
  try {
    // Array com as datas selecionadas
    const selectedDates = [selectedDateInput.value]; // Adicione mais datas conforme necessário

    // Constrói a query string com as datas
    const queryString = `dates[]=${encodeURIComponent(selectedDateInput.value)}`;
    
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/stripe/blocked-times?${queryString}`);
    
    blockedTimes.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar horários bloqueados:", error);
  }
};

const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".input-with-button")) {
        showDropdown.value = false;
      }
    };

    const fetchCourts = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data.sort((a: Court, b: Court) => {
          const numberA = parseInt(a.nome.match(/\d+/)?.[0] || "0");
          const numberB = parseInt(b.nome.match(/\d+/)?.[0] || "0");
          return numberA - numberB;
        });
      } catch (error) {
        console.error("Erro ao buscar quadras:", error);
      }
    };

    const fetchOccupiedTimes = async (date: string, courtId: number) => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${date}/${courtId}`
        );
        return response.data.map((reservation: any) => ({
          id: reservation.id,
          horario: reservation.horario,
          nome: reservation.usuario.nome,
          pagamentoPorStripe: reservation.pagamentoPorStripe
        }));
      } catch (error) {
        console.error("Erro ao buscar horários ocupados:", error);
        return [];
      }
    };

    const unAvailable = (hour: string) => {
      const time = dayjs(`${selectedDate.value.format("YYYY-MM-DD")} ${hour}`, "YYYY-MM-DD HH:mm");
      return time.isBefore(dayjs());
    };

    // Função para verificar se o horário já passou
const isPastSlot = (hour: string) => {
  const time = dayjs(`${selectedDate.value.format("YYYY-MM-DD")} ${hour}`, "YYYY-MM-DD HH:mm");
  return time.isBefore(dayjs());
};

// Função generateAvailableTimes com distinção de horários passados e ocupados
const generateAvailableTimes = async () => {
  const times: AvailableTime[] = [];
  const dayOfWeek = selectedDate.value.day(); // Obter o dia da semana (0 = domingo, 6 = sábado)

  // Inicializar todos os horários de 07:00 às 21:00
  for (let hour = 7; hour <= 21; hour++) {
    const time = selectedDate.value.clone().hour(hour).minute(0).second(0);

    times.push({
      id: 0, // Inicializa com um ID 0
      hour: time.format("HH:mm"),
      available: true, // Disponível se não for um horário passado
      isPast: isPastSlot(time.format("HH:mm")), // Define isPast para horários que já passaram
      reservadoPor: null, // Inicialmente sem reserva
      pagamentoPorStripe: null
    });
  }

  // Aplicar ocupação e reservas por quadra com regras específicas para dias da semana e finais de semana
  for (const court of courts.value) {
    const courtNumber = extractCourtNumber(court.nome);
    if (courtNumber === null) continue;

    const occupiedTimes = await fetchOccupiedTimes(
      selectedDate.value.format("YYYY-MM-DD"),
      court.id
    );

    let courtTimes = times.map((t) => {
      const reservation = occupiedTimes.find(
        (res: any) => res.horario === t.hour
      );

      return {
        ...t,
        available: t.available && !reservation, // Disponível se não for passado ou reservado
        reservadoPor: reservation ? reservation.nome : null, // Nome de quem reservou
        id: reservation ? reservation.id : 0, // ID da reserva, se houver
        pagamentoPorStripe: reservation ? reservation.pagamentoPorStripe : null
      };
    });

    // Aplicar regras de bloqueio personalizadas
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      // Fim de semana => só quadra 5 é liberada
      if (courtNumber !== 5) {
        courtTimes = courtTimes.map((t) => ({ ...t, available: false }));
      }
    } else {
      // Regras de seg-sex

      // Quadra 1
      if (courtNumber === 1) {
        if (dayOfWeek >= 1 && dayOfWeek <= 4) {
          // seg-qui: 07:00 - 20:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "20:00") && t.available,
          }));
        } else if (dayOfWeek === 5) {
          // sexta: 07:00 - 17:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "17:00") && t.available,
          }));
        }
      }

      // Quadra 4
      if (courtNumber === 4) {
        if (dayOfWeek >= 1 && dayOfWeek <= 4) {
          // seg-qui: 07:00 - 17:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "16:00") && t.available,
          }));
        } else if (dayOfWeek === 5) {
          // sexta: 07:00 - 22:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "22:00") && t.available,
          }));
        }
      }

      // Quadra 3
      if (courtNumber === 3 && dayOfWeek === 5) {
        // sexta: 16:00 - 22:00 indisponível
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available:
            !(t.hour >= "16:00" && t.hour <= "22:00") && t.available,
        }));
      }

      // **Novos bloqueios de horários específicos**
      // Segunda-feira 18:00 - Bloqueio Quadra 4
      if (dayOfWeek === 1 && courtNumber === 4) {
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: t.hour !== "18:00" && t.available,
        }));
      }

      // Terça-feira 17:00 - Bloqueio Quadra 2
      if (dayOfWeek === 2 && courtNumber === 2) {
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: t.hour !== "17:00" && t.available,
        }));
      }

      // Quarta-feira 19:00 - Bloqueio Quadra 5
      if (dayOfWeek === 3 && courtNumber === 5) {
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: t.hour !== "19:00" && t.available,
        }));
      }

      // Quarta-feira 20:00 - Bloqueio Quadra 3
      if (dayOfWeek === 3 && courtNumber === 3) {
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: t.hour !== "20:00" && t.available,
        }));
      }

      // Sexta-feira 17:00 - Bloqueio Quadra 2
      if (dayOfWeek === 5 && courtNumber === 2) {
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: t.hour !== "17:00" && t.available,
        }));
      }
    }

    availableTimes.value[court.id] = courtTimes;
  }

  loadingSchedules.value = false;
};

// Função para extrair o número da quadra do nome
const extractCourtNumber = (courtName: string): number | null => {
  const match = courtName.match(/\d+/); // Encontra o primeiro número no nome da quadra
  return match ? parseInt(match[0]) : null;
};


    const isSelected = (courtId: number, hour: string) => {
      return selectedHours.value.some((h) => h.courtId === courtId && h.hour === hour);
    };

    const selectHour = (courtId: number, courtName: string, hour: string, available: boolean) => {
      if (!available) return; // Impede seleção se já estiver reservado

      const selectedTime = dayjs(`${selectedDate.value.format("YYYY-MM-DD")} ${hour}`, "YYYY-MM-DD HH:mm");
      if (selectedTime.isBefore(dayjs())) {
        alert("Não é possível selecionar horários passados!");
        return;
      }

      const alreadySelected = selectedHours.value.find((h) => h.courtId === courtId && h.hour === hour);

      if (alreadySelected) {
        selectedHours.value = selectedHours.value.filter((h) => !(h.courtId === courtId && h.hour === hour));
      } else {
        selectedHours.value.push({ courtId, courtName, hour });
      }
    };

    const removeHour = (index: number) => {
      selectedHours.value.splice(index, 1);
    };

    const fetchAvailableHours = async () => {
      loadingSchedules.value = true;
      selectedHours.value = [];
      selectedDate.value = dayjs(selectedDateInput.value);

      await fetchCourts();
      await generateAvailableTimes();
    };

    const fetchSchedules = async () => {
  ensureDateIsSet(); // Garante que a data está definida
  loadingSchedules.value = true;
  selectedDate.value = dayjs(selectedDateInput.value);
  await fetchCourts();
  await generateAvailableTimes();
  await fetchBlockedTimes()
};

    const onInput = async () => {
  if (searchQuery.value.trim() === "") {
    filteredClients.value = [];
    return;
  }

  loadingClients.value = true;
  try {
    const query = searchQuery.value.trim().toLowerCase();
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/searchClients?query=${query}&_=${Date.now()}`);

    filteredClients.value = response.data.filter((client: any) => {
      const clientName = client.nome.toLowerCase();
      const clientEmail = client.email.toLowerCase();
      return clientName.startsWith(query) || clientEmail.startsWith(query);
    });

    showDropdown.value = filteredClients.value.length > 0; // Mostrar apenas se houver resultados
  } catch (error: any) {
    console.error("Erro ao buscar clientes:", error);
    filteredClients.value = []; // Limpar a lista filtrada ao ocorrer um erro
    showDropdown.value = false; // Ocultar o dropdown se houver erro
  } finally {
    loadingClients.value = false;
  }
};


    const selectClient = (client: any) => {
      selectedClient.value = client;
      searchQuery.value = `${client.nome} - ${client.email}`;
      showDropdown.value = false;
    };

    const clearClient = () => {
      selectedClient.value = null;
      searchQuery.value = "";
    };

    const saveReservation = async () => {
      loadingSchedules.value = true
  if (!selectedClient.value || selectedHours.value.length === 0) {
    return;
  }

  try {
    const payload = {
      userId: selectedClient.value.id,
      hours: selectedHours.value.map(hour => ({
        courtId: hour.courtId,
        time: hour.hour,
      })),
      date: selectedDateInput.value,
    };

    const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/horariosOcupados`, payload);

    if (response.data.success) {
      selectedHours.value = [];
      fetchAvailableHours();
      alert('Reserva realizada com sucesso!');
    } 
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      const conflicts = error.response.data.conflicts;
      alert(`Conflitos detectados`);
      window.location.reload();
    }
    else{
    console.error("Erro ao salvar reserva:", error);
    alert("Erro ao salvar a reserva. Tente novamente.");
    }
  } finally{
    loadingSchedules.value = false
  }
};


    // Funções para controle de exclusão
    const confirmDeleteHour = (id: number, hour: string) => {
      const selectedTime = dayjs(`${selectedDate.value.format("YYYY-MM-DD")} ${hour}`, "YYYY-MM-DD HH:mm");
      if (selectedTime.isBefore(dayjs())) {
        alert("Não é possível remover horários passados!");
        return;
      }
      deletingHour.value = id;
      showConfirmation.value = true;
    };

    const cancelDeleteHour = () => {
      showConfirmation.value = false;
      deletingHour.value = null;
    };

    const confirmDelete = async () => {
      loadingSchedules.value = true
      if (!deletingHour.value) return;

      try {
        const reservationId = deletingHour.value;
        await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${reservationId}`);
        fetchAvailableHours();
      } catch (error) {
        console.error("Erro ao deletar horário:", error);
      } finally {
        showConfirmation.value = false;
        deletingHour.value = null;
        loadingSchedules.value = false;
      }
    };

    const formattedDate = computed(() => dayjs(selectedDate.value).format("DD/MM/YYYY"));

    const setInitialDate = () => {
  const today = dayjs();
  const dayOfWeek = today.day(); // 0 é Domingo, 6 é Sábado

  if (dayOfWeek === 0) {
    // Se for domingo, define a data para a próxima segunda-feira
    selectedDateInput.value = today.add(1, 'day').format('YYYY-MM-DD');
  } else if (dayOfWeek === 6) {
    // Se for sábado, define a data para a próxima segunda-feira
    selectedDateInput.value = today.add(2, 'days').format('YYYY-MM-DD');
  } else {
    // Se for um dia útil, mantém a data atual
    selectedDateInput.value = today.format('YYYY-MM-DD');
  }

  // Depois de definir a data, busca os horários disponíveis
  fetchSchedules();
};

// const deletar = async() =>{
//   try {
//         await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/clear-sessions`);
//       } catch (error) {
//         console.error("Erro ao deletar horário:", error);
//       }
// }

    onMounted(() => {
      fetchCourts();
      setInitialDate();
      fetchBlockedTimes();
    });

    return {
      handleOutsideClick,
      selectedDateInput,
      courts,
      availableTimes,
      loadingSchedules,
      formattedDate,
      maxDate,
      fetchAvailableHours,
      isPastSlot,
      selectHour,
      removeHour,
      isSelected,
      searchQuery,
      onInput,
      showDropdown,
      filteredClients,
      selectClient,
      saveReservation,
      loadingClients,
      selectedClient,
      selectedHours,
      fetchSchedules,
      clearClient,
      showConfirmation,
      confirmDeleteHour,
      cancelDeleteHour,
      confirmDelete,
      blockedTimes,
      fetchBlockedTimes,
    };
  },
});
</script>

<style scoped>
.court-schedules {
  padding: 20px;
  background: linear-gradient(135deg, #6EE7B7 0%, #3B82F6 100%);
  min-height: 100vh;
}

.container {
  padding: 30px;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
}

.container h3{
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #ffffff;
}

input[type="date"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #333;
  cursor: pointer;
}

.schedule-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.court-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.court-section h4 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: #444;
}

.court-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.court-section ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #f8f9fa;
  font-size: 1.1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.court-section ul li .time-info {
  flex-grow: 1;
  text-align: left;
}

.court-section ul li .reservation-info {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: right;
}

.court-section ul li .iconTrash {
  cursor: pointer;
  color: red;
}

.court-section ul li .iconTrashStripe {
  cursor: pointer;
  color: rgb(255, 255, 255);
}

.court-section ul li .reserved-info {
  display: flex;
  align-items: center;
}

.court-section ul li .reserved-name {
  margin-left: 10px;
  margin-right: 20px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.available {
  cursor: pointer;
}

.court-section ul li.disabled {
  background-color: #e2e3e5;
  color: #777;
  cursor: not-allowed;
}

.court-section ul li.reserved {
  background-color: #f8d7da;
  color: #721c24;
}

.court-section ul li.stripe {
  background-color: #0004ff;
  color: #ffffff;
}

.court-section ul li.selected {
  background-color: #90EE90;
  color: #2E8B57;
}

.court-section ul li:hover:not(.disabled):not(.reserved) {
  background-color: #c3e6cb;
  color: #155724;
}

.selected-hours-container {
  background: linear-gradient(135deg, #6EE7B7 0%, #3B82F6 100%);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.selected-hours-container h4 {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.selected-hours {
  list-style: none;
  padding: 0;
}

.selected-hours li {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s;
}

.selected-hours li:hover {
  background-color: #f1f1f1;
}

.selected-hours li .remove {
  cursor: pointer;
  color: red;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.no-selected-hours {
  font-size: 1.2rem;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
}

.form-group {
  margin-bottom: 15px;
  text-align: center;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease;
}

.form-control:focus {
  border-color: #28a745;
  outline: none;
}

.input-with-button {
  position: relative;
}

.input-with-button input {
  width: 100%;
  padding-right: 60px;
}

.input-with-button .btn-change-client {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.input-with-button .btn-change-client:hover {
  background-color: #218838;
}

.client-dropdown {
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  margin-top: 5px;
  border-radius: 8px;
}

.client-dropdown li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.client-dropdown li:hover {
  background-color: #f1f1f1;
}

.court-section ul li.unavailable {
  background-color: #e2e3e5;
  color: #777;
  cursor: not-allowed;
}

.btn-save {
  background: linear-gradient(135deg, #6EE7B7, #3B82F6);
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-save:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.btn-save:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.reservation-form-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-width: 800px;
  margin: 40px auto;
  /* Removido text-align: left para herdar centralização */
}

.reservation-form-container h3 {
  color: #333333;
  text-align: center;
  text-shadow: none !important;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.loader-container p {
  margin: 0;
  font-size: 1.5rem;
  color: #ffffff;
}

.blocked-times-section {
  background-color: #f8d7da;
  color: #721c24;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.blocked-times-section h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.blocked-times-section ul {
  list-style: none;
  padding: 0;
}

.blocked-times-section ul li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.no-blocked-times {
  background-color: #e9ecef;
  color: #6c757d;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.loader-container-clients {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-clients {
  margin-top: 10px;
  margin-bottom: 2.5px;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  animation: spin 1s linear infinite;
}

.loader-clients p {
  font-size: 1.2rem;
  color: #000;
  text-align: center;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-container h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-button {
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
  width: 100%;
}

.modal-button.cancel {
  background-color: #ff5c5c;
  color: #fff;
}

.modal-button.cancel:hover {
  background-color: #e04848;
}

.modal-button.confirm {
  background-color: #6200ea;
  color: #ffffff;
}

.modal-button.confirm:hover {
  background-color: #4b00b2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1200px) {
  .schedule-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .schedule-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .court-section {
    padding: 15px;
  }

  h3 {
    font-size: 1.5rem;
  }

  input[type="date"] {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .schedule-grid {
    grid-template-columns: 1fr;
  }

  .container {
    padding: 10px;
  }

  .court-section {
    padding: 10px;
  }

  h3 {
    font-size: 1.3rem;
  }

  input[type="date"] {
    padding: 8px;
    font-size: 0.8rem;
  }

  .btn-save {
    font-size: 0.9rem;
    padding: 10px;
  }

  .modal-container {
    width: 90%;
    padding: 20px;
  }

  .button-group {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .court-section h4 {
    font-size: 1.1rem;
  }

  .court-section ul li {
    padding: 8px;
    font-size: 0.9rem;
  }

  .reservation-form-container {
    padding: 20px;
  }

  .selected-hours li {
    padding: 8px 15px;
  }

  .input-with-button input {
    padding-right: 50px;
  }

  .form-control {
    padding: 8px;
  }

  .modal-container h2 {
    font-size: 1.4rem;
  }

  .modal-container p {
    font-size: 0.9rem;
  }
}
</style>
