<template>
  <div class="renovar-plano">
    <HomeIcon :showArrow="true" to="/account" />

    <!-- Loader global -->
    <div class="loader-container" v-if="loading">
      <div class="loader"></div>
      <p>Carregando dados...</p>
    </div>

    <!-- Caso o plano exista e seja renovável -->
    <div v-else-if="plano && canRenew" class="container">
      <h2 class="h2">Renovação do Plano</h2>
      <div>
        <!-- Exibimos os dados conforme seja reserva ou clubinho -->
        <p><strong>Plano:</strong> {{ plano.reservaNome || plano.clubinhoNome }}</p>
        <p><strong>Início:</strong> {{ formatDate(plano.dataInicio) }}</p>
        <p><strong>Fim:</strong> {{ formatDate(plano.dataFim) }}</p>
        <p><strong>Renovação para:</strong> {{ nextRenewalDate }}</p>

        <!-- Botão para abrir o modal de pagamento -->
        <button @click="showPaymentModal = true" class="btn-renew">
          Prosseguir para Pagamento
        </button>
      </div>
    </div>

    <!-- Caso o plano exista, mas não seja renovável -->
    <div v-else-if="plano && !canRenew" class="container">
      <h2 class="h2">Renovação do Plano</h2>
      <p>O plano expirou ou já foi renovado.</p>
    </div>

    <!-- Caso não haja dados válidos -->
    <div v-else class="container">
      <h2 class="h2">Renovação do Plano</h2>
      <p>Dados inválidos, token expirado ou data fora do período de renovação.</p>
    </div>

    <!-- Modal de Confirmação -->
    <div v-if="showPaymentModal" class="modal-overlay">
      <div class="modal-container">
        <h2>Atenção!</h2>
        <p>
          Após concluir o pagamento, nossa equipe verificará seus dados em nossa base de alunos.
          Caso não seja confirmado, sua assinatura será cancelada.
        </p>
        <p>
          Você pode pagar via Stripe ou via PIX.
          Para pagar via PIX, clique no botão abaixo para enviar uma mensagem para nosso WhatsApp.
        </p>
        <div class="button-group">
          <button @click="cancelPayment" class="modal-button cancel">Cancelar</button>
          <button @click="confirmPayment" class="modal-button confirm">Continuar (Stripe)</button>
          <button @click="goToWhatsapp" class="modal-button whatsapp">
            Pagar via PIX (WhatsApp)
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, ref, onMounted } from "vue";
import dayjs from "dayjs";
import HomeIcon from "@/components/HomeIcon.vue";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51Pw6F4KJmK61rZjc8fMMEQtTQAVLpgVVK3SPeFBf96Rpp40thcF79DFSXD8F6mbfmckzQy3YATb2DcM99Mj9JSmS00xIP1C5QV"
);

export default defineComponent({
  name: "RenovarPlano",
  components: { HomeIcon },
  setup() {
    const plano = ref<any>(null);
    const nextRenewalDate = ref<string | null>(null);
    const canRenew = ref<boolean>(false);
    const loading = ref<boolean>(true);
    const showPaymentModal = ref<boolean>(false);

    /**
     * 1) Carrega e decodifica o token no backend.
     * 2) Se contiver reservaId, chamamos endpoint de reserva; se contiver clubinhoId, chamamos endpoint de clubinho.
     */
    const fetchPlanoDetails = async () => {
      const token = new URLSearchParams(window.location.search).get("token");
      if (!token) {
        loading.value = false;
        return;
      }

      try {
        // Decodifica no backend
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/decode-renovacao`,
          { token }
        );

        const accountResponse = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/account`,
          { withCredentials: true }
        );
        const user = accountResponse.data?.user;
        if (!user || user.id !== response.data.decoded.userId) {
          plano.value = null;
          return;
        }

        // A resposta contém { decoded: { ... } }
        plano.value = response.data.decoded;

        if (plano.value.reservaId) {
          // Caso seja um plano de reserva
          await handleReserva(plano.value.reservaId, plano.value.planoId);
        } else if (plano.value.clubinhoId) {
          // Caso seja um plano de clubinho
          await handleClubinho(plano.value.clubinhoId, plano.value.planoId);
        }
      } catch (error) {
        console.error("Erro ao verificar a renovação:", error);
      } finally {
        loading.value = false;
      }
    };

    const handleReserva = async (reservaId: number, planoId: number) => {
  const reservaResponse = await axios.get(
    `${process.env.VUE_APP_BACKEND_URL}/reservas/${reservaId}`
  );
  const reserva = reservaResponse.data;
  if (reserva && reserva.dataFim) {
    const today = dayjs().startOf('day');
    const endPlanDate = dayjs(reserva.dataFim);
    
    // Verifica se a dataFim do reserva bate com a dataFim esperada do token
    if (reserva.dataFim !== plano.value.dataFim) {
      plano.value = null;
      return;
    }
    
    // Se a dataFim estiver antes de hoje, dados inválidos
    if (endPlanDate.isBefore(today)) {
      plano.value = null;
      return;
    }
    
    const daysUntilEnd = endPlanDate.diff(today, "day");
    
    // Se não estiver dentro do prazo de renovação, considera dados inválidos
    if (daysUntilEnd < -1 || daysUntilEnd > 5) {
      plano.value = null;
      return;
    }
    
    canRenew.value = true;
    // 1 mês (planoId=1) ou 3 meses (planoId=2) - ajuste se necessário
    const planDuration = planoId === 1 ? 1 : 3;
    nextRenewalDate.value = endPlanDate
      .add(planDuration, "month")
      .format("DD/MM/YYYY");
  }
};

const handleClubinho = async (clubinhoId: number, planoId: number) => {
  const clubinhoResponse = await axios.get(
    `${process.env.VUE_APP_BACKEND_URL}/clubinhos/${clubinhoId}`
  );
  const clubinho = clubinhoResponse.data;
  if (clubinho && clubinho.dataFim) {
    const today = dayjs().startOf('day');
    const endPlanDate = dayjs(clubinho.dataFim);
    
    // Verifica se a dataFim do clubinho bate com a dataFim esperada do token
    if (clubinho.dataFim !== plano.value.dataFim) {
      plano.value = null;
      return;
    }
    
    // Se a dataFim estiver antes de hoje, dados inválidos
    if (endPlanDate.isBefore(today)) {
      plano.value = null;
      return;
    }
    
    const daysUntilEnd = endPlanDate.diff(today, "day");
    
    if (daysUntilEnd < -1 || daysUntilEnd > 5) {
      plano.value = null;
      return;
    }
    
    canRenew.value = true;
    // Para clubinho, usamos 1 mês como duração de renovação (ajuste se necessário)
    const planDuration = 1;
    nextRenewalDate.value = endPlanDate
      .add(planDuration, "month")
      .format("DD/MM/YYYY");
  }
};

    /**
     * Gera datas de renovação (apenas se for reserva).
     * Se for clubinho, pode ter lógica diferente ou nenhuma.
     */
     const gerarDatas = (dataFim: string, planoId: number) => {
      const dayOfWeek = plano.value.indexDia; // Dia da semana do início do plano
      const datasGeradas: string[] = [];
      let novaEndDate = dayjs(dataFim).add(planoId === 1 ? 1 : 3, 'month'); // Define 1 ou 3 meses para a renovação

      let current = dayjs(dataFim);

      while (current.isBefore(novaEndDate)) {
        if (current.day() === dayOfWeek) {
          datasGeradas.push(current.format("YYYY-MM-DD"));
        }
        current = current.add(1, 'day');
      }

      const dataExtra = dayjs(datasGeradas[datasGeradas.length - 1]).add(7, 'day');
      const dataExtraString = dataExtra.format("YYYY-MM-DD");
      datasGeradas.push(dataExtraString);
      return { datasGeradas, novaEndDate: novaEndDate.format("YYYY-MM-DD") };
    };


    /**
     * Chamado ao clicar em "Continuar (Stripe)" no modal.
     * Se for reserva, chama endpoint /stripe/create-checkout-session-renovacao
     * Se for clubinho, chama /stripe/create-checkout-session-renovacao-clubinho
     */
     const prosseguirPagamento = async () => {
  const today = dayjs().startOf('day');
  const endPlanDate = dayjs(plano.value.dataFim); // Data vinda do backend (reserva ou clubinho)
  
  // Verifica se a dataFim obtida é igual à dataFim esperada (do token)
  if (endPlanDate.format("YYYY-MM-DD") !== dayjs(plano.value.dataFim).format("YYYY-MM-DD")) {
    alert("Os dados de expiração do plano não correspondem. A página será recarregada.");
    window.location.reload();
    return;
  }
  
  // Se a dataFim já passou, dados inválidos
  if (endPlanDate.isBefore(today)) {
    alert("A data final do plano já expirou. A página será recarregada.");
    window.location.reload();
    return;
  }
  
  const daysUntilEnd = endPlanDate.diff(today, "day");
  
  // Verifica se o prazo de renovação está fora do intervalo permitido
  if (daysUntilEnd <= -1 || daysUntilEnd > 5) {
    alert("O prazo de renovação expirou. A página será recarregada.");
    window.location.reload();
    return;
  }
  
  loading.value = true;
  try {
    if (plano.value.reservaId) {
      // Renovação de Reserva
      let { datasGeradas, novaEndDate } = gerarDatas(plano.value.dataFim, plano.value.planoId);
      datasGeradas.splice(0, 1);
  
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session-renovacao`,
        {
          dates: datasGeradas,
          horarios: (plano.value.horarios || "").split(","),
          userId: plano.value.userId,
          quadraId: plano.value.idQuadra,
          plan: plano.value.planoId,
          endDate: novaEndDate,
          firstEndDate: plano.value.dataFim,
          reservaId: plano.value.reservaId,
          indexDia: plano.value.indexDia,
        }
      );
  
      const stripe = await stripePromise;
      if (stripe) {
        stripe.redirectToCheckout({ sessionId: response.data.id });
      }
    } else if (plano.value.clubinhoId) {
      // Renovação de Clubinho
      let novaEndDate = dayjs(plano.value.dataFim).add(1, 'month')
      // Se desejar, pode usar datasGeradas para outras finalidades; aqui, envia apenas novaEndDate.
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session-renovacao-clubinho`,
        {
          clubinhoId: plano.value.clubinhoId,
          userId: plano.value.userId,
          plan: plano.value.planoId,
          startDate: plano.value.dataInicio,
          endDate: novaEndDate,
          firstEndDate: plano.value.dataFim,
        }
      );
      const stripe = await stripePromise;
      if (stripe) {
        stripe.redirectToCheckout({ sessionId: response.data.id });
      }
    }
  } catch (error) {
    console.error("Erro ao prosseguir com o pagamento:", error);
  } finally {
    loading.value = false;
  }
};


    const formatDate = (date: string) => {
      return dayjs(date).format("DD/MM/YYYY");
    };

    onMounted(() => {
      fetchPlanoDetails();
    });

    // Funções do modal
    const cancelPayment = () => {
      showPaymentModal.value = false;
    };

    const confirmPayment = () => {
      showPaymentModal.value = false;
      prosseguirPagamento();
    };

    const goToWhatsapp = () => {
      const phone = "5535997681112";
      const message = encodeURIComponent("Olá, gostaria de pagar via PIX para renovar meu plano.");
      window.open(`https://wa.me/${phone}?text=${message}`, "_blank");
    };

    return {
      plano,
      nextRenewalDate,
      canRenew,
      loading,
      showPaymentModal,
      prosseguirPagamento,
      formatDate,
      cancelPayment,
      confirmPayment,
      goToWhatsapp,
    };
  },
});
</script>

<style scoped>
.renovar-plano {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
}

.container {
  background: white;
  border-radius: 10px;
  padding: 30px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
}

p {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333;
}

.btn-renew {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s;
  border: none;
}

.btn-renew:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  transform: scale(1.05);
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-container h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-button {
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
  width: 100%;
}

.modal-button.cancel {
  background-color: #ff5c5c;
  color: #fff;
}

.modal-button.cancel:hover {
  background-color: #e04848;
}

.modal-button.confirm {
  background-color: #6200ea;
  color: #fff;
}

.modal-button.confirm:hover {
  background-color: #4b00b2;
}

.modal-button.whatsapp {
  background-color: #25D366;
  color: white;
}

.modal-button.whatsapp:hover {
  background-color: #1da851;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}
</style>
